import '@babel/polyfill';
import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import '@fortawesome/fontawesome-free/css/all.css';
import * as VueGoogleMaps from 'vue2-google-maps';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import { default as msalPlugin } from "vue-msal-browser";
import { msalConfig } from "./helpers/authentication_helper"

//Vue.config.productionTip = false

// Lines below will register any components that start with the
// word Base as global components. Usefull for buttons and controls
// for instance that could be used in multiple components.
/* global require */
const requireComponent = require.context(
    // The relative path of the components folder
    './components',
    // Whether or not to look in subfolders
    false,
    // The regular expression used to match base component filenames
    /Base[A-Z]\w+\.(vue|js)$/
);

requireComponent.keys().forEach(fileName => {
    // Get component config
    const componentConfig = requireComponent(fileName);

    // Get PascalCase name of component
    const componentName = upperFirst(
        camelCase(
            // Strip the leading `./` and extension from the filename
            fileName.replace(/^\.\/(.*)\.\w+$/, '$1')
        )
    );
    // Register component globally
    Vue.component(
        componentName,
        // Look for the component options on `.default`, which will
        // exist if the component was exported with `export default`,
        // otherwise fall back to module's root.
        componentConfig.default || componentConfig
    );
});

const google_maps_api_key = 'AIzaSyCo3-NxVD_M08lVj0w0ohIGJerKfgrJy6Q';
Vue.use(VueGoogleMaps, {
    load: {
        key: google_maps_api_key,
        libraries: ['places', 'geometry', 'drawing'] // necessary for places input
    }
});

Vue.use(msalPlugin, msalConfig);

new Vue({
    vuetify,
    render: h => h(App)
}).$mount('#app');