import _ from 'lodash';

const METERS_TO_MILES_FACTOR = 0.000621371;

let rad = function (x) {
    return (x * Math.PI) / 180;
};

let deg = function (x) {
    return x * (180 / Math.PI);
};
const funcs = {
    computePathLengthMeters(path) {
        const latLngPath = path.map(
            (point) => new window.google.maps.LatLng(point.lat, point.lng)
        );
        // let latLngPath = [];
        // for (let point of path) {
        //     latLngPath.push(new new google.maps.LatLng(point.lat, point.lng));
        // }
        const distance = window.google.maps.geometry.spherical.computeLength(
            latLngPath
        );
        return distance;
    },

    computePathLengthMiles(path) {
        return _.round(
            funcs.computePathLengthMeters(path) * METERS_TO_MILES_FACTOR,
            3
        );
    },

    // Bearing is based on clockwise 360 degrees from due North.  We are considered to with in range of the
    // bearing, if calculated bearing is within degree_range degrees on either side of the checkedBearing
    checkIfBearingInRange: function (
        checkedBearing,
        startLocation,
        endLocation,
        degree_range
    ) {
        // First get the bearing from last_coord to current coord
        const bearing = funcs.getBearing(startLocation, endLocation);

        // Calculate range of 120 degrees from bearing.  60 degrees in either direction.
        // const degree_range = 60;

        // degree_rangecounterclockwise
        let rangeStart = checkedBearing - degree_range;
        let rangeEnd = checkedBearing + degree_range;

        // Adjust bearing.  if range start < 0 and bearing > 360 - degree_range then bearing = bearing -360
        // in order to normalize
        let adjustedBearing = bearing;
        if (rangeStart < 0 && bearing > 360 - degree_range) {
            adjustedBearing -= 360;
        }

        const isInRange =
            rangeStart < adjustedBearing && adjustedBearing < rangeEnd;
        // console.log(`isInRange = ${isInRange}, adjustedBearing = ${_.round(adjustedBearing, 1)}, bearing = ${_.round(bearing, 1)}`);

        return isInRange;
    },

    getBearing: function (startCoord, endCoord) {
        const startLat = rad(startCoord.lat);
        const startLng = rad(startCoord.lng);
        const endLat = rad(endCoord.lat);
        const endLng = rad(endCoord.lng);

        var dLng = endLng - startLng;

        var dPhi = Math.log(
            Math.tan(endLat / 2.0 + Math.PI / 4.0) /
                Math.tan(startLat / 2.0 + Math.PI / 4.0)
        );
        if (Math.abs(dLng) > Math.PI) {
            if (dLng > 0.0) dLng = -(2.0 * Math.PI - dLng);
            else dLng = 2.0 * Math.PI + dLng;
        }

        return (deg(Math.atan2(dLng, dPhi)) + 360.0) % 360.0;
    },

    getDistanceMeters: function (p1, p2) {
        var R = 6378137; // Earth’s mean radius in meters
        var dLat = rad(p2.lat - p1.lat);
        var dLng = rad(p2.lng - p1.lng);
        var a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(rad(p1.lat)) *
                Math.cos(rad(p2.lat)) *
                Math.sin(dLng / 2) *
                Math.sin(dLng / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c;
        return d; // returns the distance in meters
    },

    // Get Distance in meters using the Haversine formula
    getDistanceFeet: function (p1, p2) {
        return funcs.getDistanceMeters(p1, p2) * 3.28084;
    },

    // Get Distance in miles using the Haversine formula
    getDistanceMiles: function (p1, p2) {
        return funcs.getDistanceMeters(p1, p2) / 1609.344;
    },
};

export default funcs;
