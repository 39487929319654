<template>
    <div v-if="authenticated">
        <div style="background: #43b02a; position: relative; height: 120px">
            <img
                style="padding: 10px; left: 30px; position: fixed"
                src="@/assets/hyliion_logo_with_text.png"
                title="v 1.9.0"
            />
            <p
                style="
                    display: inline;
                    position: fixed;
                    left: 110px;
                    padding-top: 25px;
                    color: white;
                    fontsize: 24px;
                    font-family: Roboto;
                "
            >
                Look Ahead: Look Ahead Development and Testing Tool
            </p>
            <p
                style="
                    display: inline;
                    position: fixed;
                    left: 145px;
                    padding-top: 65px;
                    color: white;
                    fontsize: 20px;
                    font-family: Roboto;
                "
            >
                Right Click on two consecutive points along a road on the map
                and then click "Look Ahead".
            </p>
        </div>
        <div id="controls">
            <v-snackbar v-model="info_snackbar" :top="true" :timeout="5000">{{
                info_snackbarText
            }}</v-snackbar>
            <v-layout row justify-center>
                <!-- Request Type and Look Ahead Request  -->
                <v-card v-show="true" class="ma-1" height="auto" width="400">
                    <v-layout column align-center>
                        <v-btn-toggle
                            class="mt-2"
                            v-model="requestType"
                            mandatory
                        >
                            <v-btn text value="fuel_savings">
                                Fuel Savings
                            </v-btn>
                            <v-btn text value="power_assist">
                                Power Assist
                            </v-btn>
                        </v-btn-toggle>
                        <!-- <v-switch
                            justify-center
                            v-model="isRecordPositions"
                            color="blue"
                            large
                            :label="`Record Positions: ${isRecordPositions.toString()}`"
                        ></v-switch>-->
                        <v-layout class="mt-2" row align-center>
                            <v-btn
                                class="ma-1"
                                depressed
                                color="blue lighten-4"
                                :disabled="
                                    recordedPoints.length < 2 ||
                                    gettingLookAhead
                                "
                                @click="lookAheadCallAPI"
                                >Look Ahead</v-btn
                            >
                            <v-btn
                                class="ma-1"
                                depressed
                                color="blue lighten-4"
                                :disabled="recordedPoints.length === 0"
                                @click="clearData(true)"
                                >Clear Look Ahead</v-btn
                            >
                        </v-layout>
                        <span class="mt-3"
                            >Valid range between clicks: 50 to 1200 feet</span
                        >
                        <h4>
                            Max distance between clicked:
                            {{ clickedDistance }} ft
                        </h4>
                        <v-layout v-if="false" row align-center class="mx-5">
                            <v-col cols="10" sm="8" md="4">
                                <v-text-field
                                    class="mx-2"
                                    label="Miles to Look Ahead"
                                    hint="Range is 0.1 to 10.0"
                                    type="number"
                                    step="0.5"
                                    v-model="lookAheadDistanceMiles"
                                    :disabled="gettingLookAhead"
                                    :rules="[rules.GCW_Range]"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="10" sm="6" md="4">
                                <v-text-field
                                    class="mx-2"
                                    label="Heading Delta"
                                    hint="Range is 0.5 20.0"
                                    type="number"
                                    step="0.5"
                                    v-model="lookAheadHeadingDelta"
                                    :disabled="gettingLookAhead"
                                    :rules="[rules.headingDeltaRange]"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                                <v-text-field
                                    class="mx-2"
                                    label="Points Per Side"
                                    hint="Range is 0 to 49"
                                    type="number"
                                    step="1"
                                    v-model="lookAheadPointsPerSide"
                                    :disabled="gettingLookAhead"
                                    :rules="[rules.pointsPerSide]"
                                ></v-text-field>
                            </v-col>
                        </v-layout>
                        <v-btn
                            class="ma-3"
                            depressed
                            color="blue lighten-4"
                            :disabled="
                                recordedPoints.length > 1 || gettingLookAhead
                            "
                            @click="requestPathDialog = true"
                            >Add points manually</v-btn
                        >
                        <v-btn
                            class="ma-3"
                            depressed
                            color="blue lighten-4"
                            :disabled="gettingLookAhead"
                            @click="addMarkersDialog = true"
                            >Add Markers
                        </v-btn>
                        <v-select
                            v-model="serverSelect"
                            :items="serverItems"
                            item-text="name"
                            item-value="queryPathRoot"
                            label="Select Server"
                            @input="selectServer(serverSelect)"
                            solo
                        ></v-select>
                    </v-layout>
                </v-card>
                <!-- Truck Parameters and Look Ahead Request  -->
                <v-card class="ma-1" height="auto" width="400">
                    <v-layout column align-center>
                        <h2>Truck Parameters</h2>
                        <v-layout column align-center>
                            <v-layout row align-center>
                                <v-text-field
                                    class="mr-3"
                                    label="Miles to Look Ahead"
                                    hint="Range is
                                    0.1 to 100.0"
                                    type="number"
                                    step="0.5"
                                    v-model="lookAheadDistanceMiles"
                                    :disabled="gettingLookAhead"
                                    :rules="[rules.milesAheadRange]"
                                ></v-text-field>
                                <v-text-field
                                    label="Truck GCW in thousands of lbs"
                                    hint="Range is 30 to 120"
                                    type="number"
                                    step="5"
                                    v-model="lookAheadGCW_Thousand_Lbs"
                                    :disabled="gettingLookAhead"
                                    :rules="[rules.GCW_Range]"
                                ></v-text-field>
                            </v-layout>
                            <v-layout
                                row
                                align-center
                                v-show="requestType == 'power_assist'"
                            >
                                <v-text-field
                                    class="mr-3"
                                    label="Target speed"
                                    hint="Range is 40 to 85"
                                    type="number"
                                    step="5"
                                    v-model="lh_target_speed"
                                    :disabled="gettingLookAhead"
                                    :rules="[rules.target_speed_range]"
                                ></v-text-field>
                                <v-text-field
                                    label="Truck speed"
                                    hint="Range is 0 to 120"
                                    type="number"
                                    step="5"
                                    v-model="lh_truck_speed"
                                    :disabled="gettingLookAhead"
                                    :rules="[rules.truck_speed_range]"
                                ></v-text-field>
                            </v-layout>
                            <v-layout
                                v-show="requestType == 'power_assist'"
                                row
                                align-center
                            >
                                <v-text-field
                                    class="mr-3"
                                    label="Battery SOC"
                                    hint="Range is 15 to 85"
                                    type="number"
                                    step="5"
                                    v-model="lh_battery_SOC"
                                    :disabled="gettingLookAhead"
                                    :rules="[rules.SOC_range]"
                                ></v-text-field>
                                <v-text-field
                                    label="Battery temp"
                                    hint="Range is -30.0 to 50.0"
                                    type="number"
                                    step="5"
                                    v-model="lh_battery_temp"
                                    :disabled="gettingLookAhead"
                                    :rules="[rules.battery_temp_range]"
                                ></v-text-field>
                            </v-layout>
                            <v-layout row align-center>
                                <v-text-field
                                    class="mt-2"
                                    label="VIN"
                                    v-model="vin"
                                    :disabled="gettingLookAhead"
                                ></v-text-field>
                            </v-layout>
                        </v-layout>
                    </v-layout>
                </v-card>
                <!-- Optimization Parameters  -->
                <v-card
                    v-show="requestType == 'power_assist'"
                    class="ma-1"
                    height="auto"
                    width="150"
                >
                    <v-layout column align-center>
                        <h2>Opt Params</h2>
                        <v-btn
                            class="ma-1"
                            depressed
                            x-small
                            color="blue lighten-4"
                            @click="setOptParametersToDefaults"
                            >Reset to Defaults</v-btn
                        >
                        <v-layout column align-center>
                            <v-text-field
                                class="ml-2 mr-2"
                                label="max_iter"
                                hint="1 to 60, default 40"
                                type="number"
                                step="1"
                                v-model="max_iter"
                                :disabled="gettingLookAhead"
                                :rules="[rules.max_iter_range]"
                            ></v-text-field>
                            <v-text-field
                                class="ml-2 mr-2"
                                label="min_speed_percent"
                                hint="50 to 105, default 90"
                                type="number"
                                step="5"
                                v-model="min_speed_perc"
                                :disabled="gettingLookAhead"
                                :rules="[rules.min_speed_percent_range]"
                            ></v-text-field>
                            <v-text-field
                                class="ml-2 mr-2"
                                label="min_opt_soc_perc"
                                hint="20 to 85, default 20"
                                type="number"
                                step="5"
                                v-model="min_opt_soc_perc"
                                :disabled="gettingLookAhead"
                                :rules="[rules.min_opt_soc_perc_range]"
                            ></v-text-field>
                            <v-text-field
                                class="ml-2 mr-2"
                                label="max_opt_soc_perc"
                                hint="20 to 85, default 85"
                                type="number"
                                step="5"
                                v-model="max_opt_soc_perc"
                                :disabled="gettingLookAhead"
                                :rules="[rules.max_opt_soc_perc_range]"
                            ></v-text-field>
                            <v-text-field
                                class="ml-2 mr-2"
                                label="sim_time_step_s"
                                hint="0.1 to 10, default 4"
                                type="number"
                                step="0.5"
                                v-model="sim_time_step_s"
                                :disabled="gettingLookAhead"
                                :rules="[rules.sim_time_step_s_range]"
                            ></v-text-field>
                        </v-layout>
                    </v-layout>
                </v-card>
                <!-- Distances between points  -->
                <v-card v-show="false" class="ma-1" height="auto" width="390">
                    <v-layout column align-center>
                        <h2>Distances between points</h2>
                        <h3>Average Distance: {{ avgDistance }} feet</h3>
                        <h3>Max Distance: {{ maxDistance }} feet</h3>
                        <h3>Min Distance: {{ minDistance }} feet</h3>
                        <h3>
                            Distances Over 100 feet count: {{ over100Count }}
                        </h3>
                        <h3>
                            Distances Below 50 feet count: {{ below50Count }}
                        </h3>
                    </v-layout>
                </v-card>
                <!-- Path Stats  -->
                <v-card v-show="true" class="ma-1" height="auto" width="235">
                    <v-layout column align-center>
                        <h2>Path Stats</h2>
                        <h3>Distance: {{ pathDistance }} mi</h3>
                        <h3>Point Count: {{ path.length }}</h3>
                        <h2 class="mt-2">Elevation Stats</h2>
                        <h3>Min Elevation: {{ minElevation }} ft</h3>
                        <h3>Max Elevation: {{ maxElevation }} ft</h3>
                        <h3>Elevation Delta: {{ deltaElevation }} ft</h3>
                    </v-layout>
                </v-card>
                <!-- Zone Info -->
                <v-card class="ma-1" height="auto" width="235">
                    <v-layout column align-center>
                        <h2>Info</h2>
                        <h3>Zone Count: {{ zones.length }}</h3>
                        <v-layout
                            column
                            align
                            center
                            v-show="requestType == 'power_assist'"
                        >
                            <h3>
                                Iteration Count:
                                {{ optimize_iteration_count }}
                            </h3>
                            <h3>
                                Optimize Time: {{ optimize_elapsed_time }} s
                            </h3>
                        </v-layout>
                        <v-layout
                            v-show="requestType == 'fuel_savings'"
                            column
                            align-center
                        >
                            <h3>Min Grade: {{ zoneData.minGrade }}</h3>
                            <h3>
                                Min Var Hill Score:
                                {{ zoneData.minVariableChargeHillScore }}
                            </h3>
                            <h3>
                                Min Full Hill Score:
                                {{ zoneData.minFullChargeHillScore }}
                            </h3>
                        </v-layout>
                        <h3 class="mt-3">
                            Elapsed Time: {{ timeElapsedTotal }} s
                        </h3>
                    </v-layout>
                </v-card>
            </v-layout>
            <v-layout row justify-center>
                <v-card
                    v-show="requestType == 'power_assist'"
                    class="ma-1"
                    height="auto"
                    width="400"
                >
                    <v-layout v-if="false" column align-center>
                        <v-btn
                            class="ma-1"
                            depressed
                            color="blue lighten-4"
                            :disabled="lhs_data === null"
                            @click="exportLinearizedHillSegements"
                            >Export Linearized Hill Segments</v-btn
                        >
                        <v-btn
                            class="ma-3"
                            depressed
                            color="blue lighten-4"
                            :disabled="lhs_data === null"
                            @click="exportLocationGradeFile"
                            >Export Location Grade to CSV
                        </v-btn>
                    </v-layout>
                </v-card>
            </v-layout>

            <v-dialog v-model="addMarkersDialog" persistent max-width="550">
                <v-card>
                    <v-card-title class="headline"
                        >Enter Coordinate for Marker</v-card-title
                    >
                    <v-layout column align-center align-content-center>
                        <v-layout column align-center>
                            <h3>Format for marker is: lat, lng</h3>
                            <v-text-field
                                v-model="markerPoint"
                                type="text"
                                label="Marker to Add"
                                hint="e.g. 31.07444, -98.18592"
                                required
                            ></v-text-field>
                        </v-layout>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                class="mr-2"
                                color="green darken-1"
                                text
                                :disabled="markerPoint == '0.0, 0.0'"
                                @click="addMarker()"
                                >Add Marker</v-btn
                            >
                            <v-btn
                                class="ml-2"
                                color="green darken-1"
                                text
                                @click="clearMarkers()"
                                >Clear Markers</v-btn
                            >
                            <v-btn
                                class="ml-2"
                                color="green darken-1"
                                text
                                @click="addMarkersDialog = false"
                                >Cancel</v-btn
                            >
                        </v-card-actions>
                    </v-layout>
                </v-card>
            </v-dialog>

            <!-- Dialog for entering Look Ahead Request Points  -->
            <v-dialog v-model="requestPathDialog" persistent max-width="550">
                <v-card>
                    <v-card-title class="headline"
                        >Enter initial coordinates for Look Ahead</v-card-title
                    >
                    <v-layout column align-center align-content-center>
                        <v-layout column align-center>
                            <h3>Format for each coordinate is: lat, lng</h3>
                            <v-text-field
                                v-model="requestPathPoint1"
                                type="text"
                                label="First Point"
                                hint="e.g. 31.07444, -98.18592"
                                required
                            ></v-text-field>
                            <v-text-field
                                v-model="requestPathPoint2"
                                type="text"
                                label="Second Point"
                                hint="e.g 31.07585, -98.18609"
                                required
                            ></v-text-field>
                        </v-layout>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                class="mr-2"
                                color="green darken-1"
                                text
                                :disabled="
                                    requestPathPoint1 == '0.0, 0.0' ||
                                    requestPathPoint2 == '0.0, 0.0' ||
                                    isReadyForLookAheadInputs === false
                                "
                                @click="
                                    requestPathDialog = false;
                                    lookAheadFromDialog();
                                "
                                >Look Ahead</v-btn
                            >
                            <v-btn
                                class="ml-2"
                                color="green darken-1"
                                text
                                @click="requestPathDialog = false"
                                >Cancel</v-btn
                            >
                        </v-card-actions>
                    </v-layout>
                </v-card>
            </v-dialog>

            <v-progress-linear
                :active="gettingLookAhead"
                v-show="gettingLookAhead"
                :height="6"
                :striped="true"
                class="ma-3"
                indeterminate
                color="blue darken-2"
            ></v-progress-linear>
        </div>
        <!-- Get Look Ahead requests logged for trucks by truck_id and start and end times -->
        <div>
            <v-container grid-list-md>
                <v-layout
                    v-if="requestType == 'power_assist'"
                    row
                    wrap
                    id="selectors"
                >
                    <!-- <v-flex xs12 lg11></v-flex>
                    <v-flex xs12 lg1>
                        <v-icon x-large style="cursor:pointer;" @click="showHelpLegend()">help</v-icon>
                    </v-flex>-->

                    <v-flex xs12 lg2></v-flex>
                    <v-flex xs12 lg4>
                        <v-menu
                            ref="menu1"
                            v-model="menu1"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="dateStarting"
                                    label="Start Date"
                                    hint="MM/DD/YYYY format"
                                    persistent-hint
                                    prepend-icon="event"
                                    @blur="dateStart = parseDate(dateStarting)"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="dateStart"
                                no-title
                                @input="menu1 = false"
                                @change="populateCompanyList"
                            ></v-date-picker>
                        </v-menu>
                    </v-flex>

                    <v-flex xs12 lg4>
                        <v-menu
                            v-model="menu2"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="dateEnding"
                                    label="End Date"
                                    hint="MM/DD/YYYY format"
                                    persistent-hint
                                    prepend-icon="event"
                                    @blur="dateEnd = parseDate(dateEnding)"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="dateEnd"
                                no-title
                                @input="menu2 = false"
                                @change="populateCompanyList"
                            ></v-date-picker>
                        </v-menu>
                    </v-flex>

                    <v-flex xs12 lg2></v-flex>
                    <v-flex xs12 lg4>
                        <v-select
                            v-model="companySelect"
                            :items="companyItems"
                            item-text="name"
                            item-value="id"
                            label="Select Company"
                            @input="populateTruckList(companySelect)"
                            solo
                        ></v-select>
                    </v-flex>

                    <v-flex xs12 lg4>
                        <v-select
                            v-model="truckSelect"
                            :items="truckItems"
                            item-text="name"
                            item-value="id"
                            label="Select Truck"
                            @input="populateRequestList()"
                            solo
                        ></v-select>
                    </v-flex>

                    <v-flex xs12 lg4>
                        <v-select
                            v-model="requestSelect"
                            :items="requestItems"
                            item-text="request_time"
                            item-value="id"
                            label="Select Request"
                            @input="loadLookAheadResponse()"
                            solo
                        ></v-select>
                        <v-checkbox
                            v-show="requestSelect !== -1"
                            v-model="toggleRecalculation"
                            label="Calculate lookahead using API"
                            :disabled="gettingLookAhead"
                        ></v-checkbox>
                    </v-flex>
                </v-layout>
            </v-container>
            <v-layout
                v-if="pathDistance > 0 && requestType == 'fuel_savings'"
                class="ml-2"
                justify-center
                row
                fill-height
            >
                <v-flex xs2>
                    <v-text-field
                        class="ml-3"
                        label="Initial speed (mph)"
                        type="number"
                        step="5"
                        value="65"
                        v-model="dvSpeed"
                        @change="updateDeltaVelocities"
                    ></v-text-field>
                </v-flex>
                <v-flex xs2>
                    <v-text-field
                        class="ml-3"
                        label="Weight (lbs)"
                        type="number"
                        value="76000"
                        step="5000"
                        v-model="dvWeight"
                        @change="updateDeltaVelocities"
                    ></v-text-field>
                </v-flex>
                <v-btn
                    class="ma-2"
                    depressed
                    color="blue lighten-4"
                    @click="updateDeltaVelocities"
                    >Re-calculate Speeds</v-btn
                >
            </v-layout>
            <vue-highcharts
                v-show="path.length > 0"
                :options="options"
                ref="lineCharts"
            ></vue-highcharts>
        </div>
        <div id="map_container">
            <gmap-map
                ref="gmapRef"
                id="map"
                :center="center"
                :zoom="12"
            ></gmap-map>
            <div id="geozone-panel">
                <v-card class="ma-3" width="auto">
                    <v-layout class="ma-2" align-center column>
                        <h2>Geozone {{ gzIndex + 1 }}</h2>
                        <v-layout row>
                            <h3>N = {{ gzNorth }}</h3>
                            <h3 class="ml-2">W = {{ gzWest }}</h3>
                        </v-layout>
                        <v-layout row>
                            <h3>S = {{ gzSouth }}</h3>
                            <h3 class="ml-2">E = {{ gzEast }}</h3>
                        </v-layout>
                        <v-layout row>
                            <h2 class="ma-2">{{ gzName }}</h2>
                        </v-layout>
                    </v-layout>
                </v-card>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import VueHighcharts from 'vue2-highcharts';
import copy from 'clipboard-copy';
import store from 'store2';
import jwt from 'jsonwebtoken';
import saveAs from 'file-saver';
import jsonexport from 'jsonexport/dist';
import { LightenDarkenColor } from 'lighten-darken-color';
import { lineIntersect } from '@turf/turf';
import { lineString, polygon } from '@turf/helpers';

import mapHelper from '../helpers/map_helper';
import elevationHelper from '../helpers/elevation_helper';
import pathHelper from '../helpers/path_helper';

//<editor-fold desc="Highcharts related objects">

let options = {
    chart: {
        zoomType: 'x',
    },
    title: {
        text: 'Elevations',
        x: -20, //center
    },

    lang: {
        thousandsSep: ',',
        decimalPoint: '.',
    },
    xAxis: {
        title: {
            text: 'Distance (miles)',
        },
    },
    yAxis: [
        {
            // Primary yAxis
            // Used to set a minimum and maximum for y-axis elvations
            // min: 400,
            // max: 1600,
            title: {
                text: 'Elevation (feet)',
            },
            plotLines: [
                {
                    value: 0,
                    width: 1,
                    color: '#808080',
                },
            ],
        },
        // {
        //     // Secondary yAxis
        //     title: {
        //         text: 'SOC %',
        //     },
        //     opposite: true,
        // },
        // {
        //     // Tertiary yAxis
        //     title: {
        //         text: 'Velocity MPH',
        //     },
        //     opposite: true,
        // },
    ],

    tooltip: {
        style: {
            fontWeight: 'bold',
            fontSize: '20px',
            opacity: 0.65,
            // split: true,
        },
        formatter: function () {
            let seriesName = this.series.getName();
            let s =
                // (this.point.options.category.index !== undefined
                //     ? "<b> category index: " +
                //     this.point.options.category.index.toFixed(0)
                //     : "") +
                // "<br />" +
                // (this.point.index !== undefined
                //     ? "<b> index: " + this.point.index.toFixed(0)
                //     : "") +
                // "<br />" +
                '<b> distance: ' +
                this.x.toFixed(2) +
                (seriesName !== 'SOC_Perc' &&
                seriesName !== 'V_MPH' &&
                seriesName !== 'V_MPH_No_Assist' &&
                seriesName !== 'V_MPH_D_455' &&
                seriesName !== 'SPAM_MAX_DCL'
                    ? ' mi<br />  <b>elevation: ' + this.y.toFixed(1) + ' ft'
                    : '') +
                (this.point.options.category.grade !== undefined
                    ? '<br /> grade: ' +
                      this.point.options.category.grade.toFixed(2) +
                      '%'
                    : '') +
                (this.point.options.category.soc !== undefined
                    ? '<br /> SOC%: ' +
                      this.point.options.category.soc.toFixed(1)
                    : '') +
                (this.point.options.category.velocity !== undefined
                    ? '<br /> MPH: ' +
                      this.point.options.category.velocity.toFixed(1) +
                      '<br /> Gear: ' +
                      this.point.options.category.gear
                    : '') +
                (this.point.options.category.spam_max_dcl !== undefined
                    ? '<br /> SPAM_MAX_DCL: ' +
                      this.point.options.category.spam_max_dcl
                    : '') +
                (this.point.options.category.hillnumber !== undefined
                    ? '<br /> hill#: ' +
                      this.point.options.category.hillnumber.toFixed(0)
                    : '') +
                (this.point.options.category.score !== undefined
                    ? '<br /> --- score: ' +
                      this.point.options.category.score.toFixed(2)
                    : '') +
                (this.point.options.category.hilldistance !== undefined
                    ? '<br /> --- hill distance: ' +
                      this.point.options.category.hilldistance.toFixed(2) +
                      ' mi'
                    : '') +
                (this.point.options.category.hillgrade !== undefined
                    ? '<br /> --- average grade: ' +
                      this.point.options.category.hillgrade.toFixed(2) +
                      ' %'
                    : '') +
                (this.point.options.category.heading !== undefined
                    ? '<br /> --- heading: ' +
                      this.point.options.category.heading.toFixed(2) +
                      '°'
                    : '');
            return s;
        },
    },
    legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
        borderWidth: 0,
    },
    plotOptions: {},
};

const colors = [
    '#ffb366',
    '#ffa64d',
    '#4dffff',
    '#33ffff',
    '#ffcc99',
    '#ffbf80',
    '#66ffff',
    '#1affff',
    '#00ffff',
];

let pathElevation = {
    name: 'elevation',
    id: 'elevation',
    type: 'area',
    turboThreshold: 0,
    threshold: null,
    fillColor: {
        linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
        },
        stops: [
            [0, colors[0]],
            [3, colors[3]],
        ],
    },
};

let pathElevationRaw = {
    name: 'rawElevation',
    id: 'rawElevation',
    type: 'line',
    turboThreshold: 0,
    threshold: null,
    color: '#222222',
    opacity: 0.1,
    lineWidth: 1,
    cropThreshold: 9999,
};

let downhills = {
    name: 'downhill',
    id: 'downhill',
    type: 'line',
    color: '#0000b3',
    lineWidth: 2,
    cropThreshold: 9999,
};

let downhillLabelsSpeed = {
    name: 'delta speed labels',
    id: 'labelsSpeed',
    type: 'line',
    cropThreshold: 9999,
    lineWidth: 0,
    enableMouseTracking: false,
    marker: {
        enabled: false,
    },
    dataLabels: {
        enabled: true,
        y: -6,
        x: 6,
        style: {
            fontWeight: 'bold',
            fontSize: '16px',
        },
        format: '{point.options.category.dv}',
    },
    states: {
        hover: {
            enabled: false,
        },
    },
    tooltip: { enabled: false },
};

let lhs = {
    name: 'LHS',
    id: 'linearized_hill_segments',
    type: 'line',
    turboThreshold: 0,
    threshold: null,
    color: '#556622',
    lineWidth: 3,
    cropThreshold: 9999,
    marker: {
        enabled: true,
    },
};

let lhs_orig = {
    name: 'LHS_ORIGINAL',
    id: 'linearized_hill_segments_original',
    type: 'line',
    turboThreshold: 0,
    threshold: null,
    color: '#001188',
    lineWidth: 3,
    cropThreshold: 9999,
    marker: {
        enabled: true,
    },
};

let SOC_Axis_Options = {
    id: 'SOC-axis',
    title: { text: 'SOC %' },
    lineWidth: 2,
    lineColor: '#007A21',
    min: 0,
    max: 100,
    opposite: true,
};

let Velocity_Axis_Options = {
    id: 'Velocity-axis',
    title: { text: 'Velocity mph' },
    lineWidth: 2,
    lineColor: '#00217A',
    min: 0,
    max: 100,
    opposite: true,
};

let SPAM_MaxDCL_Axis_Options = {
    id: 'SpamMaxDCL-axis',
    title: { text: 'SPAM MAX DCL' },
    lineWidth: 2,
    lineColor: '#7A217A',
    min: 0,
    max: 279,
    opposite: true,
};

let SOC = {
    name: 'SOC_Perc',
    id: 'SOC_Perc',
    yAxis: 'SOC-axis',
    type: 'line',
    turboThreshold: 0,
    threshold: null,
    color: '#007A21',
    lineWidth: 2,
    marker: {
        enabled: false,
    },
    cropThreshold: 9999,
};

let Diesel455Velocity = {
    name: 'V_MPH_D_455',
    id: 'V_MPH_D_455',
    yAxis: 'Velocity-axis',
    type: 'line',
    turboThreshold: 0,
    threshold: null,
    color: '#009C2A',
    lineWidth: 2,
    marker: {
        enabled: false,
    },
    cropThreshold: 9999,
};

let NoAssistVelocity = {
    name: 'V_MPH_No_Assist',
    id: 'V_MPH_No_Assist',
    yAxis: 'Velocity-axis',
    type: 'line',
    turboThreshold: 0,
    threshold: null,
    color: '#9C002A',
    lineWidth: 2,
    marker: {
        enabled: false,
    },
    cropThreshold: 9999,
};

let Velocity = {
    name: 'V_MPH',
    id: 'V_MPH',
    yAxis: 'Velocity-axis',
    type: 'line',
    turboThreshold: 0,
    threshold: null,
    color: '#00217A',
    lineWidth: 2,
    marker: {
        enabled: false,
    },
    cropThreshold: 9999,
};

let SpamMaxDcl = {
    name: 'SPAM_MAX_DCL',
    id: 'SPAM_MAX_DCL',
    yAxis: 'SpamMaxDCL-axis',
    type: 'line',
    turboThreshold: 0,
    threshold: null,
    color: '#7A217A',
    lineWidth: 2,
    marker: {
        enabled: false,
    },
    cropThreshold: 9999,
};

let plotLineOptions = {
    name: 'zonePlotLine',
    width: 3,
    id: 'zonePlotLine',
    color: 'black',
    value: 0.0,
    zIndex: 100,
};
//</editor-fold Highcharts related objects">

export default {
    name: 'LookAhead',
    components: {
        VueHighcharts,
    },

    data: () => ({
        authenticated: false,
        /* global google */

        requestType: 'power_assist',
        // network related
        queryPathRoot: 'https://hyliionapi.hyliion.com/',
        // network related
        // MSAL

        msalConfig: {
            token: {},
            account: {}
        },

        clickedDistance: 0.0,

        // truck and route parameters
        lookAheadDistanceMiles: 25.0,
        lookAheadGCW_Thousand_Lbs: 75.0,
        lh_target_speed: 65.0,
        lh_truck_speed: 65.0,
        lh_battery_SOC: 80.0,
        lh_battery_temp: 30.0, // Celsius
        vin: '888DDD',

        // optimization input parameters
        max_iter: 60,
        min_speed_perc: 90,
        min_opt_soc_perc: 20,
        max_opt_soc_perc: 85,
        sim_time_step_s: 4,

        // optimization related data
        optimize_iteration_count: 0,
        optimize_elapsed_time: 0,

        lookAheadHeadingDelta: 1.0,
        lookAheadPointsPerSide: 16,
        timeBetweenGeocodeRequests: 1500, // ms -> 1 second
        rules: {
            // Route and Truck Parameter Ranges
            milesAheadRange: (value) =>
                (value >= 0.1 && value <= 100.0) || 'Value is out of range',
            GCW_Range: (value) =>
                (value >= 30 && value <= 120.0) || 'Value is out of range',
            target_speed_range: (value) =>
                (value >= 40 && value <= 85.0) || 'Value is out of range',
            truck_speed_range: (value) =>
                (value >= 0 && value <= 120.0) || 'Value is out of range',
            SOC_range: (value) =>
                (value >= 15 && value <= 85.0) || 'Value is out of range',
            battery_temp_range: (value) =>
                (value >= -30.0 && value <= 50.0) || 'Value is out of range',

            // Optimization Parameter Ranges
            max_iter_range: (value) =>
                (value >= 1 && value <= 80) || 'Value is out of range',
            min_speed_percent_range: (value) =>
                (value >= 50 && value <= 105) || 'Value is out of range',
            min_opt_soc_perc_range: (value) =>
                (value >= 20 && value <= 85) || 'Value is out of range',
            max_opt_soc_perc_range: (value) =>
                (value >= 20 && value <= 85) || 'Value is out of range',
            sim_time_step_s_range: (value) =>
                (value >= 0.1 && value <= 10) || 'Value is out of range',

            headingDeltaRange: (value) =>
                (value >= 0.5 && value <= 20.0) || 'Value is out of range',
            pointsPerSide: (value) =>
                (value >= 0 && value <= 49) || 'Value is out of range',
        },
        isRecordPositions: true,
        recordedPoints: [],
        pointsToSnap: [],
        snappedData: null,
        drawnPaths: [],
        path: [],
        pathBounds: null,
        pathHeading: null, // Heading of last two points
        pathDistance: 0,
        pathDistancesFeet: [],
        // pathDistancesMiles: [],
        pathGradesRaw: [],
        pathElevations: [],
        pathElevationsRaw: [],
        pathGrades: [],
        pathDistanceElevations: [],
        pathDistanceElevationsRaw: [],
        pathDownhills: [],
        markers: [],

        gettingLookAhead: false,
        iterationCount: 0,
        timeElapsedPath: 0,
        timeElapsedElevations: 0,
        timeElapsedAdjustElevations: 0,
        timeElapsedTotal: 0,
        avgDistance: 0,
        maxDistance: 0,
        minDistance: 0,
        over100Count: 0,
        below50Count: 0,
        minElevation: 0,
        maxElevation: 0,
        deltaElevation: 0,
        startTime: new Date(),
        startTimeElevations: new Date(),
        googleApiKey: 'AIzaSyCo3-NxVD_M08lVj0w0ohIGJerKfgrJy6Q',
        gmgs: null,
        mapHelper,
        elevationHelper,
        pathHelper,
        requestPathDialog: false,
        requestPathPoint1: '0.0, 0.0',
        requestPathPoint2: '0.0, 0.0',

        // Map and Elevation Chart marker related
        addMarkersDialog: false,
        markerPoint: '0.0, 0.0',
        mapMarkers: [],

        // default to Cedar Park to keep it simple
        // change this to whatever makes sense
        center: { lat: 31.074445, lng: -98.185929 },
        info_snackbar: false,
        info_snackbarText: '',

        // Geozones related
        zones: [],
        zoneData: {
            minGrade: 0,
            minVariableChargeHillScore: 0,
            minFullChargeHillScore: 0,
        },
        selected_zone: null,
        zoneIndex: -1,
        gzNorth: 0.0,
        gzWest: 0.0,
        gzSouth: 0.0,
        gzEast: 0.0,
        gzSettingsArrayNumber: 0,
        gzName: '',
        gzIndex: -1,

        // Highcharts related
        options: options,
        // Elevations
        pathElevation: pathElevation,
        pathElevationRaw: pathElevationRaw,
        downhills: downhills,
        downhillLabelsSpeed: downhillLabelsSpeed,
        plotLineOptions: plotLineOptions,
        plotLines: [],
        // SOC
        SOC_Axis_Options: SOC_Axis_Options,
        SOC: SOC,
        soc_array: [],
        // Velocity
        Velocity_Axis_Options: Velocity_Axis_Options,
        velocity_array: [],
        Velocity: Velocity,
        no_assist_velocity_array: [],
        NoAssistVelocity: NoAssistVelocity,
        diesel_455_velocity_array: [],
        Diesel455Velocity: Diesel455Velocity,
        // SPAM MAX DCL
        SPAM_MaxDCL_Axis_Options: SPAM_MaxDCL_Axis_Options,
        SPAM_MAX_VALUE: 279,
        spam_max_dcl_array: [],
        SpamMaxDcl: SpamMaxDcl,

        lhs_data: null,
        lhs_orig_data: null,
        lhs_array: [],
        lhs_orig_array: [],
        lhs: lhs,
        lhs_orig: lhs_orig,
        dvSpeed: 65,
        dvWeight: 76000,

        // PlaceIds and Address investigation
        // markers: [],

        // Get requests from Look Ahead
        dateStart: moment().format('YYYY-MM-DD'),
        dateEnd: moment().format('YYYY-MM-DD'),
        dateStarting: moment().format('MM/DD/YYYY'),
        dateEnding: moment().format('MM/DD/YYYY'),
        menu1: false,
        menu2: false,
        companyItems: [],
        companySelect: -1,
        trucks: [],
        truckItems: [],
        truckSelect: -1,
        requestSelect: -1,
        requestItems: [],
        toggleRecalculation: false,

        pathToDraw: null,
        isReadyForLookAheadInputs: true,

        // Server Selection Related
        serverItems: [
            { name: 'Local Host', queryPathRoot: 'http://localhost:3000/' },
            {
                name: 'Development',
                queryPathRoot: 'https://hyliionapi-dev.hyliion.com/',
            },
            {
                name: 'Production',
                queryPathRoot: 'https://hyliionapi.hyliion.com/',
            },
        ],
        serverSelect: {
            name: 'Production',
            queryPathRoot: 'https://hyliionapi.hyliion.com/',
        },
    }),

    async created() {
        // Authenticate via MSAL on load;
        let vm = this;
        let msalInstance = vm.$msal;

        let accounts = msalInstance.getAllAccounts();
        if (accounts.length == 0 ) {
            await vm.authenticate();
        }
        vm.authenticated = true;
        let tokenResponse = await msalInstance.handleRedirectPromise();
        accounts = msalInstance.getAllAccounts();


        if (accounts.length === 0) {
            await vm.authenticate();
            return
        }
        if (!tokenResponse) {
            const account = accounts[0]
            tokenResponse = await msalInstance.getSilentToken(account, msalInstance.config.auth.scopes)

            if (!tokenResponse){
                await vm.authenticate()
            }
        }

        vm.msalConfig.token = tokenResponse
        vm.msalConfig.account = accounts[0]

    },

    mounted() {
        /* global google */
        let vm = this;
        vm.lineCharts = vm.$refs.lineCharts.chart;
    },

    watch: {
        dateStart() {
            let vm = this;
            vm.dateStarting = vm.formatDate(vm.dateStart);
            console.log('Selected start date --> ' + vm.dateStarting);
        },
        dateEnd() {
            let vm = this;
            vm.dateEnding = vm.formatDate(vm.dateEnd);
            console.log('Selected end date --> ' + vm.dateEnding);
        },
        toggleRecalculation() {
            let vm = this;
            if (vm.requestSelect !== -1) {
                vm.loadLookAheadResponse();
            }
        },
        authenticated: function (newVal, oldVal){
            if (oldVal === false && newVal === true){
                const vm = this;
                axios.interceptors.request.use(
                    // Pre-request handler
                    async (req) => {
                        // Determine if request is outbound to Hyliion APIs or local dev server
                        const isHyliionRequest = req.url.includes('hyliion') || req.url.includes('localhost') || req.url.includes('127.0.0.1')
                        if (isHyliionRequest) {
                            const token = await this.getToken().catch(async()=>{await vm.authenticate()});
                            req.headers.authorization = `Bearer ${token}`
                        }
                        return req
                    },
                    // Error handler
                    function(err) {
                        console.log(err);
                    }
                )
                vm.geolocate();
                vm.populateCompanyList();

                vm.$gmapApiPromiseLazy().then(() => {
                    vm.gmgs = google.maps.geometry.spherical;
                    vm.myGoogleMap = vm.$refs.gmapRef.$mapObject;
                    vm.directionsService = new google.maps.DirectionsService();
                    vm.geocoder = new google.maps.Geocoder();
                    // vm.placesService = new google.maps.places.PlacesService(vm.myGoogleMap);
                    vm.fsobInfoWindow = new google.maps.InfoWindow();
                    vm.pathBounds = new google.maps.LatLngBounds();
                    vm.drawingManager = new google.maps.drawing.DrawingManager();
                    vm.myGoogleMap.addListener('rightclick', function (e) {
                        vm.mapRightClickHandler(e.latLng);
                    });
                    vm.myGoogleMap.setOptions({
                        fullscreenControlOptions: {
                            position: google.maps.ControlPosition.TOP_LEFT,
                        },
                        mapTypeControlOptions: {
                            position: google.maps.ControlPosition.TOP_LEFT,
                        },
                        zoomControlOptions: {
                            position: google.maps.ControlPosition.LEFT_TOP,
                        },
                        streetViewControlOptions: {
                            position: google.maps.ControlPosition.LEFT_TOP,
                        },
                    });
                });
            }
        }
    },

    methods: {

        authenticate: async function() {

            const vm = this;
            const msalInstance = vm.$msal;

            try {
                await msalInstance.authenticate();
                vm.authenticated = true;
            } catch (err) {
                // handle error
                console.log("msal error: ", err);
            }

        },

        getToken: async function () {
            let vm = this;
            let msalInstance = vm.$msal;
            let account = msalInstance.getAllAccounts()[0];

            if (!account) {
                await vm.authenticate()
            }
            const tokenResponse = await msalInstance.getSilentToken(account, msalInstance.config.auth.scopes)
            
            if(!tokenResponse || (tokenResponse && tokenResponse.accessToken)) {
                await vm.authenticate()
            }
            return tokenResponse.accessToken

        },

        //</editor-fold>

        //<editor-fold desc="General Purpose/Utility">
        formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split('-');
            return `${month}/${day}/${year}`;
        },

        parseDate(date) {
            if (!date) return null;

            const [month, day, year] = date.split('/');
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        },

        selectServer: function (queryPathRootValue) {
            let vm = this;
            vm.queryPathRoot = queryPathRootValue;
        },

        getCompaniesWithRequests: async function () {
            
            let vm = this;
            let distinctIds = [];
            let { startTime, endTime } = vm.getStartAndEndTimes();
            let query =
                vm.queryPathRoot +
                `look_ahead/companies?start=${startTime}&end=${endTime}`;
            await axios
                .get(query)
                .then((response) => {
                    const resLength = response.data.length;
                    if (resLength > 0) {
                        distinctIds = response.data.slice();
                    } else {
                        console.log(
                            'Response array for company items is empty...'
                        );
                    }
                })
                .catch((error) => {
                    console.error(
                        'Axios error on getting company items ==> ' + error
                    );
                    vm.errorHandler(error);
                });
            return distinctIds;
        },

        populateCompanyList: async function () {
            let vm = this;
            vm.companySelect = -1;
            vm.truckSelect = -1;
            vm.requestSelect = -1;
            let companiesWithRequestsIds = await vm.getCompaniesWithRequests();
            let query = vm.queryPathRoot + 'company';
            await axios
                .get(query)
                .then((response) => {
                    const resLength = response.data.length;
                    if (resLength > 0) {
                        let allCompanies = response.data.slice();
                        vm.companyItems = _.filter(allCompanies, function (
                            company
                        ) {
                            return _.includes(
                                companiesWithRequestsIds,
                                company.id
                            );
                        });
                        vm.companyItems = _.sortBy(vm.companyItems, ['name']);
                    } else {
                        console.log(
                            'Response array for company items is empty...'
                        );
                    }
                })
                .catch((error) => {
                    console.error(
                        'Axios error on getting company items ==> ' + error
                    );
                    vm.errorHandler(error);
                });
        },

        populateTruckList: async function (company_id) {
            let vm = this;
            // reset dropdowns
            vm.truckSelect = -1;
            vm.requestSelect = -1;
            // clear truck items array
            vm.truckItems.length = 0;
            let { startTime, endTime } = vm.getStartAndEndTimes();
            let query =
                vm.queryPathRoot +
                `look_ahead\\trucks?company_id=${company_id}&start=${startTime}&end=${endTime}`;
            await axios
                .get(query)
                .then((response) => {
                    const resLength = response.data.length;
                    if (resLength > 0) {
                        vm.truckItems = response.data.slice();
                        vm.truckItems = _.sortBy(vm.truckItems, ['name']);
                    } else {
                        console.log(
                            'Response array for truckItems is empty...'
                        );
                    }
                })
                .catch((error) => {
                    console.error(
                        'Axios error on getting truckItems ==> ' + error
                    );
                    vm.errorHandler(error);
                });
        },

        getStartAndEndTimes: function () {
            let vm = this;
            let start = vm.formatDate(vm.dateStart);
            let startDate = new Date(new Date(start).getTime());
            // console.log('Start Date = ' + startDate);
            let end = vm.formatDate(vm.dateEnd);
            let endDate = new Date(new Date(end).getTime());
            endDate.setHours(23, 59, 59, 0);
            // console.log('End Date = ' + endDate);
            let startTime = startDate.getTime() / 1000;
            let endTime = endDate.getTime() / 1000;
            if (startTime > endTime) {
                vm.info_snackbarText = `Please choose a start date that is before the end date`;
                vm.info_snackbar = true;
            }
            return { startTime, endTime };
        },

        populateRequestList: async function () {
            let vm = this;
            // reset dropdown
            vm.requestSelect = -1;
            vm.requestItems.length = 0;
            // clear truck items array
            // vm.truckItems.length = 0;
            let { startTime, endTime } = vm.getStartAndEndTimes();
            let query =
                vm.queryPathRoot +
                `look_ahead\\requests?truck_id=${vm.truckSelect}&start=${startTime}&end=${endTime}`;
            await axios
                .get(query)
                .then((response) => {
                    const resLength = response.data.length;
                    if (resLength > 0) {
                        let items = response.data.slice();
                        vm.requestItems = items.map(function (item) {
                            return {
                                id: item.id,
                                request_time: moment
                                    .unix(item.request_time)
                                    .format(`YYYY-MM-DD h:mm:ss A`),
                                request: item.request,
                            };
                        });
                    } else {
                        console.log(
                            'Response array for requestItems is empty...'
                        );
                    }
                })
                .catch((error) => {
                    console.error(
                        'Axios error on getting truckItems ==> ' + error
                    );
                    vm.errorHandler(error);
                });
        },

        loadLookAheadResponse: async function () {
            let vm = this;
            let lookAheadSource = vm.toggleRecalculation ? 'API' : 'Database';
            console.log('Loading lookAhead response from ', lookAheadSource);
            vm.buildLookAheadRequest();
            await vm.lookAheadCallAPI();
        },

        buildLookAheadRequest: function () {
            let vm = this;
            // console.log(`buildLookAheadRequest: Hello`);
            let requestObject = _.find(vm.requestItems, {
                id: vm.requestSelect,
            });
            let request = requestObject.request;
            // console.log('request: ', request)

            vm.requestType =
                parseInt(request.requestType) === 0
                    ? 'fuel_savings'
                    : 'power_assist';
            vm.lookAheadDistanceMiles = parseFloat(request.distance);
            vm.lookAheadGCW_Thousand_Lbs = parseFloat(request.weight);
            vm.lh_target_speed = parseFloat(request.targetSpeed);
            vm.lh_truck_speed = parseFloat(request.truckSpeed);
            vm.lh_battery_SOC = parseFloat(request.batterySOC);
            vm.lh_battery_temp = parseFloat(request.batteryTemp);
            vm.vin = request.vin;

            let points = request.requestPoints;
            vm.pointsToSnap.length = 0;
            vm.recordedPoints.length = 0;
            for (let point of points) {
                let requestPoints = point.split(',');
                let coord = new google.maps.LatLng({
                    lat: Number(requestPoints[0]),
                    lng: Number(requestPoints[1]),
                });

                vm.recordedPoints.push({
                    lat: Number(requestPoints[0]),
                    lng: Number(requestPoints[1]),
                });

                vm.pointsToSnap.push(coord);
            }
            // console.log(JSON.stringify(vm.pointsToSnap));
            // console.log(JSON.stringify(request));
        },

        geolocate: function () {
            let vm = this;
            navigator.geolocation.getCurrentPosition(() => {
                // navigator.geolocation.getCurrentPosition((position) => {
                this.center = {
                    lat: vm.center.lat,
                    lng: vm.center.lng,
                };
                // this.center = {
                //     lat: position.coords.latitude,
                //     lng: position.coords.longitude
                // };
            });
        },

        errorHandler: function (source, errorObj) {
            let vm = this;
            let errorDesc = '';
            if (errorObj.response !== null && errorObj.response !== undefined) {
                errorDesc = `${errorObj.message} - ${errorObj.response.data.error}`;
            } else {
                errorDesc = errorObj.message;
            }
            vm.info_snackbarText = `${source}: ${errorDesc}`;
            vm.info_snackbar = true;
        },

        exportLinearizedHillSegements: function () {
            let vm = this;
            let blob = new Blob([JSON.stringify(vm.lhs_data)], {
                type: 'text/plain;charset=utf-8',
            });
            saveAs(blob, `lhs.json`);
        },

        exportLocationGradeFile: function () {
            let vm = this;
            let latLngGradeList = [];
            vm.path.forEach((element, index) => {
                latLngGradeList.push({
                    lat: element.lat,
                    lng: element.lng,
                    grade: _.round(vm.pathGrades[index], 3),
                    // Convert from feet to meters
                    elevation: _.round(vm.pathElevations[index] * 0.3048, 3),
                    // Convert from files to meters
                    distance: _.round(
                        vm.pathDistanceElevations[index].x * 1609.34,
                        1
                    ),
                });
            });

            jsonexport(latLngGradeList, function (err, csv) {
                if (err) {
                    console.log(err);
                }
                let fileName = `lookAheadPowerAssist.csv`;
                let blob = new Blob([csv], {
                    type: 'text/plain;charset=utf-8',
                });
                saveAs(blob, fileName);
            });
        },

        //</editor-fold>

        //<editor-fold desc="Look Ahead Functions">
        mapRightClickHandler(latLng) {
            let vm = this;
            if (vm.isReadyForLookAheadInputs) {
                console.log(
                    `map right click ${latLng}, record positions: ${vm.isRecordPositions}`
                );
                if (vm.isRecordPositions) {
                    vm.recordedPoints.push({
                        lat: latLng.lat(),
                        lng: latLng.lng(),
                    });
                    vm.pointsToSnap.push(latLng);
                }
                if (vm.recordedPoints.length > 1) {
                    // If the user has created a path, then they want to make a request using
                    // based on the two entered points.  Alter the ToggleRequest to make this happen
                    vm.toggleRecalculation = true;
                    vm.clearPaths();
                    vm.drawRecordedPointsPath();
                    const lastIndex = vm.recordedPoints.length - 1;
                    const p1 = vm.recordedPoints[lastIndex - 1];
                    const p2 = vm.recordedPoints[lastIndex];
                    vm.getMaxDistancePoints(p1, p2);
                } else {
                    vm.clickedDistance = 0;
                }
            }
        },

        getMaxDistancePoints(p1, p2) {
            let vm = this;
            const distance = mapHelper.getDistanceFeet(p1, p2);
            vm.clickedDistance = _.round(
                Math.max(distance, vm.clickedDistance)
            );
        },

        copyPointsUsedToClipboard(points) {
            let vm = this;
            let pointsUsed = [];
            points.forEach((point) => {
                pointsUsed.push(
                    new google.maps.LatLng({
                        lat: _.round(point.lat(), 5),
                        lng: _.round(point.lng(), 5),
                    })
                );
            });
            let pointsUsedStr = pointsUsed.toString();
            // console.log(pointsUsedStr);
            pointsUsedStr = pointsUsedStr.replace(/[()]/g, '');
            //pointsUsedStr = pointsUsedStr.replace(/[ ]/g, '');
            // console.log(pointsUsedStr);
            let t = 0;
            pointsUsedStr = pointsUsedStr.replace(/,/g, function (match) {
                t++;
                return t % 2 === 0 ? '\n' : match;
                //return (t % 2) === 0 ? "&point" : match;
            });
            // pointsUsedStr = `distance=${vm.lookAheadDistanceMiles}&point=${pointsUsedStr}`;
            // console.log(pointsUsedStr);
            copy(pointsUsedStr);
            vm.info_snackbarText = `initial Look Ahead points copied to clipboard`;
            vm.info_snackbar = true;
        },

        setOptParametersToDefaults() {
            let vm = this;
            vm.max_iter = 60;
            vm.min_speed_perc = 90;
            vm.min_opt_soc_perc = 20;
            vm.max_opt_soc_perc = 85;
            vm.sim_time_step_s = 4;
        },

        async lookAheadCallAPI() {
            let vm = this;
            vm.clearPaths();
            vm.isReadyForLookAheadInputs = false;
            vm.gettingLookAhead = true;
            vm.lhs_data = null;
            vm.lhs_orig_data = null;
            vm.copyPointsUsedToClipboard(vm.pointsToSnap);
            vm.startTime = new Date();
            let pathPointsStr = '';
            for (let pathPoint of vm.pointsToSnap) {
                pathPointsStr += `&point=${_.round(
                    pathPoint.lat(),
                    5
                )},${_.round(pathPoint.lng(), 5)}`;
            }
            let zones = null;
            const requestType = vm.requestType == 'fuel_savings' ? 0 : 1;

            let queryParams =
                `requestType=${requestType}&distance=${vm.lookAheadDistanceMiles}&weight=${vm.lookAheadGCW_Thousand_Lbs}` +
                `${pathPointsStr}&targetSpeed=${vm.lh_target_speed}&truckSpeed=${vm.lh_truck_speed}` +
                `&batterySOC=${vm.lh_battery_SOC}&batteryTemp=${vm.lh_battery_temp}&vin=${vm.vin}`;
            if (requestType === 1) {
                let optParams =
                    `&max_iter=${vm.max_iter}&min_speed_perc=${vm.min_speed_perc}&min_opt_soc_perc=${vm.min_opt_soc_perc}` +
                    `&max_opt_soc_perc=${vm.max_opt_soc_perc}&sim_time_step_s=${vm.sim_time_step_s}`;
                queryParams += optParams;
            }
            let query = '';

            // We should only check the recalculation toggle for Power Assist requests.
            // If the user selected the recalculation toggle we use a different query to hit the correct API
            if (requestType === 1 && !vm.toggleRecalculation) {
                console.log('using the stored response');
                query =
                    vm.queryPathRoot +
                    `look_ahead/recorded_response?id=${vm.requestSelect}`;
            } else {
                query = `${vm.queryPathRoot}look_ahead?${queryParams}`;
            }
            console.log(`lookAheadCallAPI: query = ${query}`);

            await axios
                .get(query)
                .then((response) => {
                    if (response.data.path.length === 0) {
                        throw new Error('No Data Returned From the Server.');
                    }
                    let meta_data = response.data.meta_data;
                    vm.pathDistance = meta_data.distance;
                    vm.timeElapsedTotal = meta_data.totalElapsedTime;
                    vm.avgDistance = meta_data.avgDistance;
                    vm.maxDistance = meta_data.maxDistance;
                    vm.minDistance = meta_data.minDistance;
                    vm.over100Count = meta_data.over100Count;
                    vm.below50Count = meta_data.below50Count;
                    vm.pathDownhills = response.data.downhills;
                    vm.path = response.data.path;
                    vm.pathDistancesFeet = response.data.pathDistancesFeet;
                    vm.pathGrades = response.data.pathGrades.map((item) => {
                        return _.round(item, 2);
                    });
                    vm.pathElevations = response.data.pathElevations.map(
                        (item) => {
                            return _.round(item, 1);
                        }
                    );

                    zones = response.data.zones;
                    vm.zoneData = response.data.zones.meta_data;
                    if (requestType === 1) {
                        vm.optimize_iteration_count =
                            meta_data.optimize_info.iteration_count;
                        vm.optimize_elapsed_time =
                            meta_data.optimize_info.elapsed_time;
                        vm.optimize_first_result =
                            meta_data.optimize_info.first_result;
                        vm.optimize_last_result =
                            meta_data.optimize_info.last_result;
                        vm.optimize_diesel455_result =
                            meta_data.optimize_info.diesel_455_result;
                        vm.lhs_data = response.data.linearized_hill_segments;
                        vm.lhs_orig_data = response.data.original_lhs;
                    }
                })
                .catch((error) => {
                    console.error(
                        'Axios error on getting look ahead ==> ' + error
                    );
                    vm.errorHandler('lookAhead', error);
                    vm.clearData();
                });

            if (vm.pathElevations.length > 0) {
                // vm.pathDistancesFeet = vm.pathHelper.getDistanceFeetArray(vm.path);
                // Also convert from meters to feet
                vm.pathElevationsRaw = vm.path.map((item) => {
                    return item.elev * 3.28084;
                });
                vm.minElevation = _.round(_.min(vm.pathElevations), 2);
                vm.maxElevation = _.round(_.max(vm.pathElevations), 2);
                vm.deltaElevation = _.round(
                    vm.maxElevation - vm.minElevation,
                    2
                );
                vm.pathGradesRaw = vm.pathHelper.createGradientArray(
                    vm.pathElevationsRaw,
                    vm.pathDistancesFeet
                );

                // Edit elevations where elevations are wrong because road went
                // over a bridge or through a tunnel.
                // vm.pathHelper.adjustElevations(
                //     vm.pathDistancesFeet,
                //     vm.pathElevations,
                //     vm.pathGradesRaw
                // );

                vm.pathDistanceElevations = vm.pathHelper.createDistanceElevations(
                    vm.pathDistancesFeet,
                    vm.pathElevations,
                    vm.pathGrades
                );

                vm.pathDistanceElevationsRaw = vm.pathHelper.createDistanceElevations(
                    vm.pathDistancesFeet,
                    vm.pathElevationsRaw,
                    vm.pathGradesRaw
                );

                // Power Assist

                if (requestType === 1) {
                    // Setup the No Assist Velocity Array
                    vm.no_assist_velocity_array.length = 0;
                    for (let result of vm.optimize_first_result) {
                        if (result.dist_m === null) continue; // ignore entries with a null distance
                        let opt_dist = _.round(result.dist_m * 0.000621371, 3);
                        let closest = _.sortBy(vm.pathDistanceElevations, [
                            function (o) {
                                return Math.abs(opt_dist - o.x);
                            },
                        ]);
                        // find and save the index within for the path
                        // so when user hovers over point, it can draw marker on map on path
                        // in correct position.
                        let found_index = _.findIndex(
                            vm.pathDistanceElevations,
                            ['x', closest[0].x]
                        );
                        let v_mph = _.round(result.vel_m_s * 2.23694, 1);
                        vm.no_assist_velocity_array.push({
                            x: closest[0].x,
                            y: v_mph,
                            category: {
                                velocity: v_mph,
                                gear: result.gear,
                                index: found_index,
                            },
                        });
                    }

                    // Setup the Diesel 455 Velocity Array
                    vm.diesel_455_velocity_array.length = 0;
                    for (let result of vm.optimize_diesel455_result) {
                        if (result.dist_m === null) continue; // ignore entries with a null distance
                        let opt_dist = _.round(result.dist_m * 0.000621371, 3);
                        let closest = _.sortBy(vm.pathDistanceElevations, [
                            function (o) {
                                return Math.abs(opt_dist - o.x);
                            },
                        ]);
                        // find and save the index within for the path
                        // so when user hovers over point, it can draw marker on map on path
                        // in correct position.
                        let found_index = _.findIndex(
                            vm.pathDistanceElevations,
                            ['x', closest[0].x]
                        );
                        let v_mph = _.round(result.vel_m_s * 2.23694, 1);
                        vm.diesel_455_velocity_array.push({
                            x: closest[0].x,
                            y: v_mph,
                            category: {
                                velocity: v_mph,
                                gear: result.gear,
                                index: found_index,
                            },
                        });
                    }

                    // Setup the SPAM DCL array
                    vm.spam_max_dcl_array.length = 0;
                    // SOC, Assisted Velocity
                    vm.soc_array.length = 0;
                    vm.velocity_array.length = 0;
                    for (let result of vm.optimize_last_result) {
                        if (result.dist_m === null) continue; // ignore entries with a null distance
                        let opt_dist = _.round(result.dist_m * 0.000621371, 3);
                        let closest = _.sortBy(vm.pathDistanceElevations, [
                            function (o) {
                                return Math.abs(opt_dist - o.x);
                            },
                        ]);
                        // find and save the index within for the path
                        // so when user hovers over soc, it can draw marker on map on path
                        // in correct position.
                        let found_index = _.findIndex(
                            vm.pathDistanceElevations,
                            ['x', closest[0].x]
                        );
                        vm.soc_array.push({
                            x: closest[0].x,
                            y: result.soc_perc,
                            category: {
                                soc: result.soc_perc,
                                index: found_index,
                            },
                        });

                        let v_mph = _.round(result.vel_m_s * 2.23694, 1);
                        vm.velocity_array.push({
                            x: closest[0].x,
                            y: v_mph,
                            category: {
                                velocity: v_mph,
                                gear: result.gear,
                                index: found_index,
                            },
                        });

                        // let normalized_spam_max_dcl = _.round(
                        //     (result.spam_max_dcl / vm.SPAM_MAX_VALUE) * 100,
                        //     1
                        // );
                        vm.spam_max_dcl_array.push({
                            x: closest[0].x,
                            y: result.spam_max_dcl,
                            category: {
                                spam_max_dcl: _.round(result.spam_max_dcl),
                                index: found_index,
                            },
                        });
                    }

                    // Linearized Hill Segments
                    vm.lhs_array.length = 0;
                    if (vm.lhs_data) {
                        for (let [i, o] of vm.lhs_data.entries()) {
                            // Add first point of segment
                            // If this is the first segement add the first points.
                            // Otherwise the seconds point of the prior segment is the first point of the current segment
                            if (i === 0) {
                                vm.lhs_array.push({
                                    x: _.round(
                                        o.distance_from_start * 0.000621371,
                                        2
                                    ), // convert from meters to miles and round to nearest 100th
                                    y: _.round(
                                        o.hill_start_elevation * 3.28084,
                                        1
                                    ), // convert from meters to feet
                                    category: {
                                        grade: o.hill_grade,
                                    },
                                });
                            }
                            // Add second point of segment
                            vm.lhs_array.push({
                                x: _.round(
                                    (o.distance_from_start + o.hill_distance) *
                                        0.000621371,
                                    2
                                ), // convert from meters to miles and round to nearest 100th
                                y: _.round(o.hill_end_elevation * 3.28084, 1), // convert from meters to feet
                                category: {
                                    grade: o.hill_grade,
                                },
                            });
                        }
                    }

                    //Original Linearized Hill Segments before chopping for 0.6 mi max path length.
                    vm.lhs_orig_array.length = 0;
                    if (vm.lhs_orig_data) {
                        for (let [i, o] of vm.lhs_orig_data.entries()) {
                            // Add first point of segment
                            // If this is the first segement add the first points.
                            // Otherwise the seconds point of the prior segment is the first point of the current segment
                            if (i === 0) {
                                vm.lhs_orig_array.push({
                                    x: _.round(
                                        o.distance_from_start * 0.000621371,
                                        2
                                    ), // convert from meters to miles and round to nearest 100th
                                    y: _.round(
                                        o.hill_start_elevation * 3.28084,
                                        1
                                    ), // convert from meters to feet
                                    category: {
                                        grade: o.hill_grade,
                                    },
                                });
                            }
                            // Add second point of segment
                            vm.lhs_orig_array.push({
                                x: _.round(
                                    (o.distance_from_start + o.hill_distance) *
                                        0.000621371,
                                    2
                                ), // convert from meters to miles and round to nearest 100th
                                y: _.round(o.hill_end_elevation * 3.28084, 1), // convert from meters to feet
                                category: {
                                    grade: o.hill_grade,
                                },
                            });
                        }
                    }
                }

                vm.gettingLookAhead = false;
                vm.drawLookAheadPath();
                vm.drawZones(zones.zones);
                vm.renderChart();
            }
        },

        lookAheadFromDialog() {
            let vm = this;
            try {
                console.log(`lookAheadFromDialog()`);
                let firstPointValues = _.split(vm.requestPathPoint1, ',');
                let firstPoint = new google.maps.LatLng({
                    lat: Number(firstPointValues[0]),
                    lng: Number(firstPointValues[1]),
                });
                vm.mapRightClickHandler(firstPoint);
                let secondPointValues = _.split(vm.requestPathPoint2, ',');
                let secondPoint = new google.maps.LatLng({
                    lat: Number(secondPointValues[0]),
                    lng: Number(secondPointValues[1]),
                });
                vm.mapRightClickHandler(secondPoint);
                console.log(
                    `firstPoint = ${firstPoint.lat()}, ${firstPoint.lng()}`
                );
                console.log(
                    `secondPoint.lat = ${secondPoint.lat()}, ${secondPoint.lng()}`
                );

                vm.lookAheadCallAPI();
            } catch (err) {
                let message = ` lookAheadFromDialog(): Error with manually entered points: ${err.message}`;
                console.log(message);
                vm.info_snackbarText = message;
                vm.info_snackbar = true;
            }
        },
        //</editor-fold Look Ahead Functions>

        clearData(userInitiated = false) {
            let vm = this;
            console.log(`clearData`);
            vm.dateStarting = vm.formatDate(
                new Date().toISOString().substr(0, 10)
            );
            vm.dateEnding = vm.formatDate(
                new Date().toISOString().substr(0, 10)
            );
            vm.isReadyForLookAheadInputs = true;
            vm.clearPaths();
            vm.clearZones();
            vm.recordedPoints.splice(0);
            vm.snappedData = null;
            vm.pointsToSnap.splice(0);
            vm.path.splice(0);
            vm.pathHeading = null;
            vm.pathDistance = 0;
            vm.iterationCount = 0;
            vm.markers.splice(0);
            vm.timeElapsedPath = 0;
            vm.timeElapsedElevations = 0;
            vm.timeElapsedAdjustElevations = 0;
            vm.timeElapsedTotal = 0;
            vm.avgDistance = 0;
            vm.maxDistance = 0;
            vm.minDistance = 0;
            vm.over100Count = 0;
            vm.below50Count = 0;
            vm.pathDistancesFeet.splice(0);
            vm.pathElevationsRaw.splice(0);
            vm.pathGradesRaw.splice(0);
            vm.pathElevations.splice(0);
            vm.pathGradesRaw.splice(0);
            vm.resetToDefaults();
            if (vm.poiMarker) {
                vm.poiMarker.setMap(null);
            }
            vm.gettingLookAhead = false;
            if (userInitiated) {
                vm.clickedDistance = 0;
                vm.info_snackbarText =
                    'Look Ahead Cleared.  Ready for next Look Ahead.';
                vm.info_snackbar = true;
            }
            vm.zoneData = {
                minGrade: 0,
                minVariableChargeHillScore: 0,
                minFullChargeHillScore: 0,
            };
            vm.lhs_data = null;
            vm.lhs_orig_data = null;
        },

        clearPaths() {
            let vm = this;
            // console.log(`clearPaths`);
            // Clear the paths
            for (let path of vm.drawnPaths) {
                path.setMap(null);
            }

            // clear the markers
            for (let marker of vm.markers) {
                marker.setMap(null);
            }

            // Clear the bounds used in fitToBounds
            vm.pathBounds = new google.maps.LatLngBounds();
        },

        drawRecordedPointsPath() {
            let vm = this;
            console.log(`drawRecordedPointsPath`);
            const strokeColor = '#FF0000'; // red
            const strokeOpacity = 0.75; // 75% opaque
            const strokeWeight = 3; // pixel width for line.
            vm.drawPath(
                vm.recordedPoints,
                strokeColor,
                strokeOpacity,
                strokeWeight
            );
        },

        drawLookAheadPath() {
            let vm = this;
            console.log(`drawLookAheadPath`);
            let strokeColor = '#0000FF'; // blue
            const strokeOpacity = 1.0; // 100% opaque
            const strokeWeight = 3; // pixel width for line.

            let latLngPath = [];
            if (!(vm.path[0] instanceof google.maps.LatLng)) {
                for (let item of vm.path) {
                    latLngPath.push(
                        new google.maps.LatLng({
                            lat: item.lat,
                            lng: item.lng,
                        })
                    );
                }
            } else {
                latLngPath = vm.path;
            }
            vm.drawPath(
                latLngPath,
                strokeColor,
                strokeOpacity,
                strokeWeight,
                true
            );
        },

        drawPath(path, color, opacity, weight, fitToBounds = false) {
            let vm = this;
            if (path.length > 1) {
                vm.pathToDraw = new google.maps.Polyline({
                    path: path,
                    strokeColor: color,
                    strokOpacity: opacity,
                    strokeWeight: weight,
                });

                vm.pathToDraw.setMap(vm.myGoogleMap);

                vm.drawnPaths.push(vm.pathToDraw);

                // let bounds = new google.maps.LatLngBounds();
                if (fitToBounds) {
                    for (let coord of path) {
                        vm.pathBounds.extend(coord);
                    }
                    vm.myGoogleMap.fitBounds(vm.pathBounds);
                }
            }
        },

        //<editor-fold> desc="Geozone related"
        clearZones: function () {
            const vm = this;
            console.log(`Clear  bounds on map`);
            // clear bounds drawn on map
            for (let zone of vm.zones) {
                zone.setMap(null);
            }
            vm.zones.length = 0;
            vm.selected_zone = null;
            vm.gzNorth = 0;
            vm.gzEast = 0;
            vm.gzSouth = 0;
            vm.gzWest = 0;
            vm.gzIndex = -1;
            vm.gzName = '';
        },

        drawZones: function (zones) {
            const vm = this;
            vm.clearZones();
            if (zones) {
                for (const zone of zones) {
                    let bounds = {
                        north: zone.bounds.north,
                        south: zone.bounds.south,
                        east: zone.bounds.east,
                        west: zone.bounds.west,
                    };
                    let color = '#0000FF';
                    let boundsToDraw = new google.maps.Rectangle({
                        bounds: bounds,
                        strokeColor: color,
                        strokeWeight: 2,
                        strokeOpacity: 0.8,
                        fillColor: color,
                        fillOpacity: 0.35,
                    });
                    boundsToDraw.setMap(vm.myGoogleMap);
                    let newZone = boundsToDraw;
                    newZone.info = zone;
                    newZone.addListener('click', function () {
                        vm.selectGeozone(this);
                    });
                    newZone.addListener('mouseover', function () {
                        vm.highlightGeozone(this, true);
                    });
                    newZone.addListener('mouseout', function () {
                        vm.highlightGeozone(this, false);
                    });

                    vm.zones.push(newZone);
                }
            }
        },

        selectGeozone: function (zone) {
            let vm = this;
            if (vm.selected_zone) {
                vm.selected_zone.isSelected = false;
                vm.highlightGeozone(vm.selected_zone, false);
            }
            zone.isSelected = true;
            vm.selected_zone = zone;
            vm.updateGeozoneDisplayValues(zone);
            vm.showIntercepts();
            vm.highlightGeozone(zone, true);
        },

        highlightGeozone: function (zone, isMouseOver) {
            // let vm = this;
            let mouseOverStrokeColor = '#FF80AB'; // pink
            let color = '#0000FF';
            const highlightZone = isMouseOver || zone.isSelected;
            let mouseOverFillColor = LightenDarkenColor(color, 80);
            let strokeColor = highlightZone ? mouseOverStrokeColor : color;
            let strokeWeight = highlightZone ? 4.0 : 2.0;
            let fillColor = highlightZone ? mouseOverFillColor : color;

            zone.setOptions({
                strokeColor: strokeColor,
                strokeWeight: strokeWeight,
                fillColor: fillColor,
            });

            // Show Geozone values if moused over, else show nothing
            // or Geozone value for currently selected geozone
            // if (isMouseOver) {
            //     vm.updateGeozoneDisplayValues(gzrec, isMouseOver);
            // } else {
            //     vm.updateGeozoneDisplayValues(vm.gzrec, isMouseOver);
            // }
        },

        updateGeozoneDisplayValues(zone, isMouseOver = false) {
            let vm = this;
            if (zone && (zone.isSelected || isMouseOver)) {
                vm.gzNorth = _.round(zone.info.bounds.north, 5);
                vm.gzEast = _.round(zone.info.bounds.east, 5);
                vm.gzSouth = _.round(zone.info.bounds.south, 5);
                vm.gzWest = _.round(zone.info.bounds.west, 5);
                vm.gzIndex = _.findIndex(vm.zones, function (gz) {
                    return gz.info.uuid === zone.info.uuid;
                });
                vm.gzName = zone.info.hill_name;
            } else {
                vm.gzNorth = 0;
                vm.gzEast = 0;
                vm.gzSouth = 0;
                vm.gzWest = 0;
                vm.gzIndex = -1;
                vm.gzName = '';
            }
        },

        //</editor-fold> desc="Geozone related"

        //<editor-fold> desc="Intercept/Plotline related"
        // Gets the polygon for the passed in geozone
        getZonePolygon(zone) {
            // let vm = this;
            let polygon = [];
            const gz = zone.info.bounds;
            polygon.push([gz.north, gz.west]);
            polygon.push([gz.north, gz.east]);
            polygon.push([gz.south, gz.east]);
            polygon.push([gz.south, gz.west]);
            polygon.push([gz.north, gz.west]);
            return polygon;
        },

        // Gets the intersections between either the currently selected polygon
        // or the hovered over polygon and the path.
        findTheIntersections() {
            let vm = this;
            console.log(`findTheIntersections()`);
            const polygonCoordinates = vm.getZonePolygon(vm.selected_zone);
            const zonePolygon = polygon([polygonCoordinates], {
                name: 'zone polygon',
            });
            let path = vm.path;

            let coordinates = [];
            for (let point of path) {
                coordinates.push([point.lat, point.lng]);
            }
            const aLineString = lineString(coordinates);
            const intersects = lineIntersect(zonePolygon, aLineString);
            return intersects;
        },

        findXIntercept(point, path) {
            // console.log(`findXIntercept(): ${ point.toString() } `);
            const interceptPoint = { lat: point[0], lng: point[1] };
            let xIntercept = 0;
            const THRESHOLD_DISTANCE = 50; // meters
            const BEARING_RANGE = 60; // degrees on each side
            let bearing = 0.0;
            let foundIndex = 0;
            // find the x intercept by finding the closest point on the path to the point
            // passed in.  We stop searching once we are within the threshold distance ant
            // the heading is no longer within tolorence.
            for (const [i, pathPoint] of path.entries()) {
                const distance = mapHelper.getDistanceMeters(
                    pathPoint,
                    interceptPoint
                );
                if (distance < THRESHOLD_DISTANCE) {
                    foundIndex = i;
                    bearing = Math.abs(
                        mapHelper.getBearing(pathPoint, interceptPoint)
                    );
                    let found = false;
                    let nextPathPoint = null;
                    let nextDistance = 0;
                    while (!found && foundIndex + 1 < path.length) {
                        nextPathPoint = path[foundIndex + 1];
                        nextDistance = mapHelper.getDistanceMeters(
                            nextPathPoint,
                            interceptPoint
                        );
                        // Check if we have moved beyond the x intercept heading by checking
                        // to see if the bearing is 60 degrees beyond what the prior bearing was
                        if (
                            !mapHelper.checkIfBearingInRange(
                                bearing,
                                nextPathPoint,
                                interceptPoint,
                                BEARING_RANGE
                            )
                        ) {
                            // We have found the last point on the path prior to the intercept point.
                            // So get that slice of the path, add the intercept point and calculate the
                            // path distance in miles.  That calculated distance is the X-intercept.
                            let pathToIntercept = _.slice(path, 0, foundIndex);
                            pathToIntercept.push(interceptPoint);
                            // The x intercept is the distance in miles to this point.
                            // computeLenght returns path length in meters so multiply by conversion
                            // factor to get miles and then round to neares thousandth decimal place.
                            xIntercept = mapHelper.computePathLengthMiles(
                                pathToIntercept
                            );
                            found = true;
                            break;
                        } else if (nextDistance > distance) {
                            // Check if nextDistance is greater then the distance that met the distance
                            // threshold check.  If so we moved past the intercept point even though
                            // the heading tolerance check was not met.  This should almost never happen.
                            // It would basically require the geozone to encompass a very sharp turn.
                            let pathToIntercept = _.slice(path, 0, foundIndex);
                            // The x intercept is the distance in miles to this point.
                            xIntercept = mapHelper.computePathLengthMiles(
                                pathToIntercept
                            );
                            found = true;
                            break;
                        } else {
                            foundIndex += 1;
                        }
                    }
                    if (found) {
                        break;
                    }
                }
            }
            return xIntercept;
        },

        findTheXaxisIntercepts(intersects, path) {
            let vm = this;
            console.log(`findTheXaxisIntercepts`);
            let intercepts = [];
            let points = [];
            for (let feature of intersects.features) {
                points.push(feature.geometry.coordinates);
            }
            for (let point of points) {
                intercepts.push(vm.findXIntercept(point, path));
            }
            return intercepts;
        },

        showIntercepts() {
            let vm = this;
            vm.hideIntercepts();
            console.log(`showIntercepts()`);
            if (vm.selected_zone !== null) {
                const intersects = vm.findTheIntersections();
                const interceptsPath = vm.findTheXaxisIntercepts(
                    intersects,
                    vm.path
                );
                console.log(
                    `out: found ${
                        interceptsPath.length
                    } intercepts: ${interceptsPath.toString()} `
                );
                vm.AddPlotLines(interceptsPath);
            }
        },

        hideIntercepts() {
            let vm = this;
            vm.RemovePlotLines();
        },
        //</editor-fold> End of Intercept/Plotline related

        //<editor-fold> desc="Marker related"
        addMarker() {
            let vm = this;
            console.log(vm.markerPoint, 'Add Marker');
            let markerPoints = vm.markerPoint.split(',');
            let coord = new google.maps.LatLng({
                lat: Number(markerPoints[0]),
                lng: Number(markerPoints[1]),
            });

            const image = {
                url:
                    'http://maps.google.com/mapfiles/kml/paddle/red-circle-lv.png',
                //url: 'http://mt.google.com/vt/icon/name=icons/spotlight/measle_8px.png&scale=2',
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(8, 8),
            };

            const marker = new google.maps.Marker({
                map: vm.myGoogleMap,
                position: coord,
                icon: image,
                flat: true,
            });
            vm.mapMarkers.push(marker);
            vm.markerPoint = '0.0, 0.0';
        },

        clearMarkers() {
            let vm = this;
            console.log('Clear Markers');
            for (let marker of vm.mapMarkers) {
                marker.setMap(null);
            }
            vm.mapMarkers.length = 0;
        },
        //</editor-fold> Marker related

        //<editor-fold desc="Charting Related">
        resetToDefaults: function () {
            let vm = this;
            if (vm.SOC_Axis) {
                vm.SOC_Axis.remove();
                vm.SOC_Axis = undefined;
            }
            if (vm.Velocity_Axis) {
                vm.Velocity_Axis.remove();
                vm.Velocity_Axis = undefined;
            }
            if (vm.SpamMaxDcl_Axis) {
                vm.SpamMaxDcl_Axis.remove();
                vm.SpamMaxDcl_Axis = undefined;
            }
            while (vm.lineCharts.series.length > 0) {
                vm.lineCharts.series[0].remove(false);
            }
            vm.lineCharts.redraw();
            vm.lineCharts.zoomOut();
            vm.lineCharts.series.length = 0;
            if (vm.pathElevation.data) {
                vm.pathElevation.data.length = 0;
            }
            if (vm.pathElevationRaw.data) {
                vm.pathElevationRaw.data.length = 0;
            }
            if (vm.lhs.data) {
                vm.lhs.data.length = 0;
            }
            if (vm.lhs_orig.data) {
                vm.lhs_orig.data.length = 0;
            }
            if (vm.SOC.data) {
                vm.SOC.data.length = 0;
            }
            if (vm.Velocity.data) {
                vm.Velocity.data.length = 0;
            }
            if (vm.NoAssistVelocity.data) {
                vm.NoAssistVelocity.data.length = 0;
            }
            if (vm.Diesel455Velocity.data) {
                vm.Diesel455Velocity.data.length = 0;
            }
            if (vm.SpamMaxDcl.data) {
                vm.SpamMaxDcl.data.length = 0;
            }
            if (vm.downhillLabelsSpeed.data) {
                vm.downhillLabelsSpeed.data.length = 0;
            }

            vm.RemovePlotLines();
        },

        // Used to create information for a point graph of points
        // centered between each pair of downhill start and end point.
        // points can be used to selectively show information such as SOC
        // for each downhill and to be independently turned on/off separate
        // from downhill graph
        createDownhillsLabelData(downhills, distElevGrade, m = 76000, v0 = 65) {
            let calcDeltaVelocity = function (m, v0, grade, dist) {
                const g = 9.81;
                // const cr = 0.5 / 100;
                const cr = 0.65 / 100;
                const cd = 0.6;
                const area = 10;
                const rho = 1.225;

                // Calculate grade
                grade = Math.atan(grade / 100);

                let u0 = v0 * v0;
                let K1 = (rho * cd * area) / m;
                let K2 = -2 * g * (Math.sin(grade) + cr * Math.cos(grade));
                let alpha = u0 - K2 / K1;
                let u = alpha * Math.exp(-K1 * dist) + K2 / K1;
                let v = Math.sqrt(u);

                return v - v0;
            };

            m /= 2.205;
            v0 /= 2.237;

            let downHillDataLabels = [];

            // Each hill has two entries in the array followed by null entry
            for (let idx = 0; idx < downhills.length; idx += 3) {
                let downhill = downhills[idx];
                const start_index = downhill.category.index;
                const end_index = downhills[idx + 1].category.index;
                let dist = downhill.category.hilldistance * 1609.34; // convert to meters
                let dv =
                    calcDeltaVelocity(
                        m,
                        v0,
                        downhill.category.hillgrade,
                        dist
                    ) * 2.237;
                let index =
                    downhill.category.index +
                    _.round((end_index - start_index) / 2, 0);
                let downhillDataLabel = {
                    x: distElevGrade[index].x,
                    y: distElevGrade[index].y,
                    category: {
                        // soc: _.round(downhill.chart[0].category.soc, 1),
                        dv: _.round(dv, 1),
                    },
                };
                downHillDataLabels.push(downhillDataLabel);
            }
            return downHillDataLabels;
        },

        updateDeltaVelocities() {
            let vm = this;

            // Update data
            vm.downhillLabelsSpeed.data = vm.createDownhillsLabelData(
                vm.pathDownhills,
                vm.pathDistanceElevations,
                parseFloat(vm.dvWeight),
                parseFloat(vm.dvSpeed)
            );
            vm.downhillsSpeedSeries.setData(vm.downhillLabelsSpeed.data, false);
            vm.lineCharts.redraw();
        },

        renderChart() {
            let vm = this;
            vm.resetToDefaults();
            vm.lineCharts.setTitle({ text: 'Look Ahead Path Elevation Chart' });
            vm.pathElevation.data = _.cloneDeep(vm.pathDistanceElevations);
            vm.pathElevation.point = {
                events: {
                    mouseOver: function () {
                        vm.HandleMouseOver(this, vm.pathElevation.length);
                    },
                    // click: function () {
                    //     vm.HandlePathClick(this, vm.pathElevation.length);
                    // },
                },
            };
            vm.SetYAxisMinMax(vm.pathDistanceElevationsRaw);
            vm.pathElevationSeries = vm.lineCharts.addSeries(vm.pathElevation);
            vm.pathElevationSeries.show();

            vm.pathElevationRaw.data = _.cloneDeep(
                vm.pathDistanceElevationsRaw
            );
            vm.pathElevationRawSeries = vm.lineCharts.addSeries(
                vm.pathElevationRaw
            );
            vm.pathElevationRawSeries.hide();
            vm.downhills.data = _.cloneDeep(vm.pathDownhills);
            vm.downhillsSeries = vm.lineCharts.addSeries(vm.downhills);
            vm.downhillsSeries.show();

            if (vm.requestType == 'fuel_savings') {
                vm.downhillLabelsSpeed.data = _.cloneDeep(
                    vm.downhillLabelsSpeed.data
                );
                vm.downhillsSpeedSeries = vm.lineCharts.addSeries(
                    vm.downhillLabelsSpeed
                );
                vm.downhillsSpeedSeries.show();
                vm.updateDeltaVelocities();
            } else if (vm.requestType == 'power_assist') {
                vm.downhillsSeries.hide();
                vm.pathElevationSeries.hide();

                vm.lhs.data = _.cloneDeep(vm.lhs_array);
                vm.lineCharts.addSeries(vm.lhs);
                // vm.lhsSeries = vm.lineCharts.addSeries(vm.lhs);
                // vm.lhsSeries.hide();
                vm.lhs_orig.data = _.clone(vm.lhs_orig_array);
                vm.lhsOrigSeries = vm.lineCharts.addSeries(vm.lhs_orig);
                vm.lhsOrigSeries.hide();

                // Battery SOC in percent SOC from 0 to 100 %
                vm.SOC_Axis = vm.lineCharts.addAxis(vm.SOC_Axis_Options);
                vm.SOC.data = _.cloneDeep(vm.soc_array);
                vm.SOC.point = {
                    events: {
                        mouseOver: function () {
                            vm.HandleMouseOver(this);
                        },
                    },
                };
                vm.lineCharts.addSeries(vm.SOC);

                // Velocity in MPH from 0 to 100 MPH
                vm.Velocity_Axis = vm.lineCharts.addAxis(
                    vm.Velocity_Axis_Options
                );

                // Assisted Velocity
                vm.Velocity.data = _.cloneDeep(vm.velocity_array);
                vm.Velocity.point = {
                    events: {
                        mouseOver: function () {
                            vm.HandleMouseOver(this);
                        },
                    },
                };
                vm.lineCharts.addSeries(vm.Velocity);

                // No Assist Velocity
                vm.NoAssistVelocity.data = _.cloneDeep(
                    vm.no_assist_velocity_array
                );
                vm.NoAssistVelocity.point = {
                    events: {
                        mouseOver: function () {
                            vm.HandleMouseOver(this);
                        },
                    },
                };
                vm.lineCharts.addSeries(vm.NoAssistVelocity);

                // Diesel 455 Velocity
                vm.Diesel455Velocity.data = _.cloneDeep(
                    vm.diesel_455_velocity_array
                );
                vm.Diesel455Velocity.point = {
                    events: {
                        mouseOver: function () {
                            vm.HandleMouseOver(this);
                        },
                    },
                };
                vm.lineCharts.addSeries(vm.Diesel455Velocity);

                // SPAM Max DCL -- Normalized from 0 to 100%
                vm.SpamMaxDcl_Axis = vm.lineCharts.addAxis(
                    vm.SPAM_MaxDCL_Axis_Options
                );
                vm.SpamMaxDcl.data = _.cloneDeep(vm.spam_max_dcl_array);
                vm.SpamMaxDcl.point = {
                    events: {
                        mouseOver: function () {
                            vm.HandleMouseOver(this);
                        },
                    },
                };
                vm.lineCharts.addSeries(vm.SpamMaxDcl);
            }
            vm.SetPlotOptions();
            vm.lineCharts.update({
                plotOptions: {
                    series: {
                        pointStart: vm.pathElevation.data[0].x,
                    },
                },
            });
        },

        SetYAxisMinMax(pathDistanceElevations) {
            let vm = this;
            const Delta_Threshold = 500.0;
            let elevationBuffer = 100.0;
            let minElevation = _.minBy(pathDistanceElevations, 'y').y;
            let maxElevation = _.maxBy(pathDistanceElevations, 'y').y;
            let elevationDelta = maxElevation - minElevation;
            if (elevationDelta < Delta_Threshold) {
                elevationBuffer += (Delta_Threshold - elevationDelta) / 2.0;
            }
            let chartMin = minElevation - elevationBuffer;
            let chartMax = maxElevation + elevationBuffer;
            vm.lineCharts.yAxis[0].update({
                min: chartMin,
                max: chartMax,
            });
        },

        HandleMouseOver(poi) {
            let vm = this;
            if (vm.poiMarker) {
                vm.poiMarker.setMap(null);
            }

            let index = poi.options.category.index;
            vm.poiMarker = new google.maps.Marker({
                map: vm.myGoogleMap,
                position: vm.pathToDraw.getPath().getAt(index),
                flat: true,
                distance: poi.distance,
                elevation: poi.elevation,
                grade: poi.grade,
            });
        },

        SetPlotOptions() {
            let vm = this;
            vm.options.plotOptions = {
                area: {
                    marker: {
                        enabled: true,
                        radius: 1,
                    },
                    lineWidth: 1,
                    states: {
                        hover: {
                            lineWidth: 2,
                        },
                    },
                },
                line: {},
            };
        },

        AddPlotLines(plotLineValues) {
            let vm = this;
            // Plot Lines for Geozone intercepts
            for (let [i, value] of plotLineValues.entries()) {
                const plotLineOptions = _.cloneDeep(vm.plotLineOptions);
                plotLineOptions.value = value;
                plotLineOptions.id = `${plotLineOptions.id}${i + 1}`;
                const addedPlotLine = vm.lineCharts.xAxis[0].addPlotLine(
                    plotLineOptions
                );
                vm.plotLines.push(addedPlotLine);
            }
        },

        RemovePlotLines() {
            let vm = this;
            for (let plotLine of vm.plotLines) {
                vm.lineCharts.xAxis[0].removePlotLine(plotLine.id);
            }
            vm.plotLines.length = 0;
        },
        //</editor-fold desc="Charting Related">
    },
};
</script>

<style scoped>
#map_container {
    position: relative;
}

#map {
    height: 1000px;
    width: auto;
}

#geozone-panel {
    /* height: 100%; */
    position: absolute;
    top: 0;
    right: 0;
    height: auto;
    width: auto;
    background-color: aliceblue;
}

#controls {
    background-image: linear-gradient(
        rgb(67, 176, 42),
        white
    ); /* Standard syntax (must be last) */
    padding-bottom: 10px;
}
</style>
