const {
  VUE_APP_CLIENT_ID,
  VUE_APP_SCOPES,
  VUE_APP_TENANT_ID
} = process.env

const requiredVariables ={
  VUE_APP_CLIENT_ID,
  VUE_APP_SCOPES,
  VUE_APP_TENANT_ID
}

for(const key in requiredVariables) {
  const value = requiredVariables[key]
  if(!value) {
    console.error(`Required environment variable ${key} is missing, please set.`)
  }
}


export const msalConfig = {
    auth: {
      tenant: process.env.VUE_APP_TENANT_ID,
      clientId: process.env.VUE_APP_CLIENT_ID,
      authority: `https://login.microsoftonline.com/${process.env.VUE_APP_TENANT_ID}`,
      redirectUri: window.location.origin,
      scopes: [process.env.VUE_APP_SCOPES]
    },
    cache: {
      cacheLocation: 'sessionStorage'
    },
    graph: {
      url: 'https://graph.microsoft.com',
      scopes: 'User.Read'
    },
    mode: "redirect"
}
