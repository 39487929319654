<template>
    <v-app id="app">
        <v-content>
            <LookAhead />
        </v-content>
    </v-app>
</template>

<script>
// import HelloWorld from './components/HelloWorld';
import LookAhead from './components/LookAhead';

export default {
    name: 'App',
    components: {
        LookAhead
    },

    data: () => ({
        //
    })
};
</script>
