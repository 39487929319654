import _ from "lodash";

const funcs = {
  //<editor-fold> desc="utility functions">
  rad(x) {
    return (x * Math.PI) / 180;
  },

  getDistanceMeters(p1, p2) {
    // let fromLatLng = new google.maps.LatLng({ lat: p1.lat, lng: p1.lng });
    // let toLatLng = new google.maps.LatLng({ lat: p2.lat, lng: p2.lng });
    // return google.maps.geometry.spherical.computeDistanceBetween(
    //   fromLatLng,
    //   toLatLng
    // );

    let R = 6378137; // Earth’s mean radius in meters
    let dLat = this.rad(p2.lat - p1.lat);
    let dLong = this.rad(p2.lng - p1.lng);
    let a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.rad(p1.lat)) *
        Math.cos(this.rad(p2.lat)) *
        Math.sin(dLong / 2) *
        Math.sin(dLong / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = R * c;
    return d; // returns the distance in meters
  },

  // Get Distance in meters using the Haversine formula
  getDistanceFeet(p1, p2) {
    return funcs.getDistanceMeters(p1, p2) * 3.28084;
  },

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  },
  //</editor-fold> end utility functions

  //<editor-fold> desc="Elevation functions"
  adjustElevations(distances, elevations, gradients) {
    const gradeThreshold = 15.0; // 15.0 is Max Gradient expected
    let count = elevations.length;
    let index = 1;
    let startIndex = 1;
    let endIndex = 1;
    while (index < count - 2) {
      let grade = gradients[index];
      // get the grade from previous point to current point to next point
      // and also check this grade to be above the threshold, so we don't pick up
      // and smooth sections that single point anomolies
      // let checkRise = elevations[index + 1] - elevations[index - 1];
      // let checkRun = distances[index] + distances[index + 1];
      // let checkGrade = (checkRise / checkRun) * 100.0;
      // if (
      //     Math.abs(grade) > gradeThreshold &&
      //     Math.abs(checkGrade) > gradeThreshold
      // ) {
      if (Math.abs(grade) > gradeThreshold) {
        startIndex = index;
        let isGradePositive = grade > 0;
        // Find the ending point for series of indices with bad elevations
        endIndex = findEndIndex(
          startIndex,
          isGradePositive,
          gradients,
          distances,
          gradeThreshold
        );
        // If and ending index was found, modify the indices and grades
        // for all points between startIndex and EndIndex inclusive.
        if (endIndex > startIndex) {
          let startElevation = elevations[startIndex - 1];
          let endElevation = elevations[endIndex];
          // Check to see that the end elevation is within the tolerance
          // expected for the distance between the start and end points.
          let run = _.sum(distances.slice(startIndex + 1, endIndex + 1));
          let rise = endElevation - startElevation;
          //let sign = endElevation > startElevation ? 1.0 : -1.0;
          //let slope = rise / run;
          // maximum slope expected for any road multiplied by the distance
          // between the start and end points where erroneous elevations were
          // detected.
          let maxAllowedElevationChange = (run * gradeThreshold) / 100.0;
          if (Math.abs(rise) < maxAllowedElevationChange) {
            let distance = 0;
            for (let index = startIndex; index <= endIndex; index++) {
              distance += distances[index];
              let newElevation = startElevation + (distance / run) * rise;
              let newGrade =
                ((newElevation - elevations[index - 1]) / distances[index]) *
                100.0;
              elevations[index] = newElevation;
              gradients[index] = newGrade;
            }
          }
        }
        index = endIndex;
      }
      index += 1;
    }

    function findEndIndex(
      index,
      isStartGradePositive,
      gradients,
      distances,
      gradeThreshold
    ) {
      //const MaxDistance = 10560; // Max distance allowed between start and end, 2 mi
      const MaxDistance = 5280; // Max distance allowed between start and end, 1 mi
      let isFoundEndIndex = false;
      let startIndex = index;
      let endIndex = index + 1;
      let grade = gradients[endIndex];
      let isEndGradePositive = grade > 0;
      let distance = distances[endIndex];
      let count = gradients.length;
      // to find an end point:
      // distance has to be less than 0.5 miles.
      // magnitude of grade needs to be greater than threshold.
      while (distance <= MaxDistance && endIndex < count - 2) {
        if (
          isStartGradePositive !== isEndGradePositive &&
          Math.abs(grade) > gradeThreshold
        ) {
          // Now search for other side of group of erroneous elevations.
          let belowThresholdCount = 0;
          const Threshold_Count = 6;
          while (
            belowThresholdCount < Threshold_Count &&
            distance <= MaxDistance
          ) {
            endIndex += 1;
            grade = gradients[endIndex];
            distance += distances[endIndex];
            belowThresholdCount =
              Math.abs(grade) <= gradeThreshold ? belowThresholdCount + 1 : 0;
          }
          // If not distance > MaxDistance, and
          if (!(distance > MaxDistance)) {
            isFoundEndIndex = true;
          }
          break;
        } else {
          endIndex += 1;
          distance += distances[endIndex];
          grade = gradients[endIndex];
          isEndGradePositive = grade > 0;
        }
      }
      return isFoundEndIndex ? endIndex : startIndex;
    }
  },

  //</editor-fold> elevation functions

  //<editor-fold> desc="Creation of path and elevation related arrays"
  createDistanceElevations(distances, elevations, gradients) {
    let distanceElevations = [];
    let totalDistanceInMiles = 0;
    distances.forEach(function (distance, index) {
      totalDistanceInMiles += distance * 0.000189394; // convert feet to miles
      //let elevationInFeet = elevations[index];
      //let percentGradient = gradients[index];
      distanceElevations.push({
        x: totalDistanceInMiles,
        y: elevations[index],
        category: {
          grade: gradients[index],
          index: index,
        },
      });
    });
    return distanceElevations;
  },

  getDistanceFeetArray(latLngs) {
    // distances array show distance between each point in the prior point.
    // When we graph points by distance traveled on x-axis, first point will
    // have a distance of zero.  The second point will be the distance between
    // the first point to the second point.
    let distances = [];
    distances.push(0);
    for (let i = 0; i < latLngs.length - 1; i++) {
      let distanceInFeet = funcs.getDistanceFeet(latLngs[i], latLngs[i + 1]);
      distances.push(distanceInFeet);
    }
    return distances;
  },

  // Creates an array of gradient percentages based on the elevation and
  // distance arrays passed to this function.  Returns the gradient array
  createGradientArray(elevations, distances) {
    let pathGradients = [];
    // gradient for first position is 0 to align with distances array
    // where distance for first position is 0.  gradient is always indicates the
    // gradient calculated for the current point and prior point
    pathGradients.push(0);
    let i = 0;
    while (i < elevations.length - 1) {
      // rise / run * 100
      pathGradients.push(
        ((elevations[i + 1] - elevations[i]) / distances[i + 1]) * 100
      );
      i++;
    }
    return pathGradients;
  },
  //</editor-fold> Creation of path and elevation related arrays
};

export default funcs;
