import _ from 'lodash';

/* global google */

const funcs = {
    sleep: function (ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    },

    getPathElevations: function (path) {
        // let self = this;
        let elevationChunks = [];
        let sortedElevationChunks = [];
        const chunkSize = 100; // Based on GoogleMaps allowed QPS for Elevation Service
        let pathChunks = _.chunk(path, chunkSize);
        let chunkCount = pathChunks.length;
        console.log(`chunkCount is ${chunkCount}`);

        let waitTimeout_ms = 1200; // Delay 1.2 seconds between requests for 100 count chunks
        const maxGetChunkRetryCount = 8;

        return new Promise(function (resolve, reject) {
            const chunkElevations = funcs.getChunksElevations(pathChunks, waitTimeout_ms, maxGetChunkRetryCount);
            chunkElevations
                .then(async function (result) {
                    elevationChunks = result.chunks;
                    if (elevationChunks.length === chunkCount) {
                        console.log('--- Finished getting elevations for requested chunks ---');
                        // return reject({ status: 'yucky' }); -- For testing reject case
                        sortedElevationChunks = _.sortBy(elevationChunks, ['index', 'asc']);
                        let elevations = funcs.processPathElevations(sortedElevationChunks);
                        return resolve(elevations);
                    }
                })
                .catch(function handleErrors(error) {
                    console.log('getChunksElevations failed due to: ' + error.status);
                    return reject(error.status);
                });
        });
    },

    getChunksElevations: function (chunks, waitTimeout_ms, maxGetChunkRetryCount) {
        // let self = this;
        return new Promise(async function (resolve, reject) {
            let results = [];
            let chunkCount = chunks.length;
            let processedChunkCount = 0;
            let missingChunkIndices = [];

            // This loop will process each chunk of path locations.
            // The loop will wait one second before requesting the next chunk in the array
            let i = 0;
            let isHealthy = true;
            for (const chunk of chunks) {
                if (isHealthy === false) break;
                let startTime = new Date();
                // console.log('Get elevations for chunk ' + i);
                let result = await funcs.getPathChunkElevations(chunk, i).catch(async function (err) {
                    console.log(`Failed to get Elevations for chunk ${err.index} due to ${err.status}`);
                    return await funcs.retryChunk(chunk, i, waitTimeout_ms, maxGetChunkRetryCount).catch(err => {
                        isHealthy = false;
                        console.log(`Failed to get Chunk ${i} after ${maxGetChunkRetryCount} retries`);
                        reject(err);
                    });
                });

                // TODO:
                // Check if result is defined or undefined.
                //   If undefined handle errors and re-request same chunck until we get it
                // 1) Calculate time span of request to response
                // 2) Subtract above time span from waitTimeout_ms
                // 3) process the chunk
                // 4) await on a sleep of resulting time if above was a postive value
                if (result !== undefined) {
                    // console.log(`Got Elevations for chunk ${result.index}`);
                    let timeDiff = new Date() - startTime;
                    // console.log(`Elapsed Time ${timeDiff} ms`);

                    processedChunkCount++;
                    // self.pathFetchPercent = Math.round(
                    //     (processedChunkCount / chunkCount) * 100
                    // );
                    // self.fetchTimeRemainingMs =
                    //     (chunkCount - processedChunkCount) * waitTimeout_ms;

                    // push the result onto the results array
                    results.push(result);

                    // If all the chuncks have been processed, resolve this promise with the results array
                    if (processedChunkCount === chunkCount) {
                        return resolve({
                            chunks: results,
                            missingChunkIndices: missingChunkIndices
                        });
                    }

                    let timeToSleep = waitTimeout_ms - timeDiff;
                    // console.log(`Time to Sleep: ${timeToSleep} ms`);
                    if (timeToSleep > 0) {
                        await funcs.sleep(timeToSleep);
                    }
                }
                i += 1;
            }
        });
    },

    retryChunk: async function (chunk, index, timeToSleep, maxGetChunkRetryCount) {
        let self = this;
        let retryCount = 1;
        return new Promise(async function (resolve, reject) {
            for (let i = 0; i < maxGetChunkRetryCount; i++) {
                timeToSleep = timeToSleep * 2;
                await self.sleep(timeToSleep);
                let result = await funcs.getPathChunkElevations(chunk, index).catch(err => {
                    // console.log(`Retry ${retryCount}` +
                    //     `: Failed to get Elevations for chunk ${err.index} due to ${err.status}`);
                    retryCount += 1;
                    if (retryCount > maxGetChunkRetryCount) {
                        console.log(
                            `Exceeded Max Retry Count of ${maxGetChunkRetryCount}` +
                            `: Failed to get Elevations for chunk ${err.index} due to ${err.status}`
                        );
                        return reject(err, index);
                    }
                });
                if (result !== undefined) {
                    console.log(`Got Elevations for chunk ${index} on retry ${retryCount}`);
                    return resolve(result);
                }
            }
        });
    },

    getPathChunkElevations: function (chunk, index) {
        // Check if the chunk is made up of LatLng objects, if create what is needed
        let latLngChunk = [];
        if (!(chunk[0] instanceof google.maps.LatLng)) {
            for (let item of chunk) {
                latLngChunk.push(new google.maps.LatLng({
                    lat: item.latitude,
                    lng: item.longitude
                }))
            }
        } else {
            latLngChunk = chunk;
        }

        let elevator = new google.maps.ElevationService();
        return new Promise(function (resolve, reject) {
            elevator.getElevationForLocations({
                    // locations: chunk
                    locations: latLngChunk
                },
                function (results, status) {
                    if (status === 'OK') {
                        let result = {
                            index: index,
                            results: results
                        };
                        return resolve(result);
                    } else {
                        // console.log('Elevation service failed due to: ' + status);
                        return reject({
                            index: index,
                            status: status
                        });
                    }
                }
            );
        });
    },

    processPathElevations: function (elevationInfos) {
        let elevations = [];
        elevationInfos.forEach(function (elevationInfo, i) {
            console.log('Process elevations for chunk ' + i);
            elevationInfo.results.forEach(function (element) {
                elevations.push(element.elevation);
            });
        });
        return elevations;
    },

    adjustElevations: function (distances, elevations, grades) {
        const gradeThreshold = 15.0; // 15.0 is Max Gradient expected
        let count = elevations.length;
        let index = 1;
        let startIndex = 1;
        let endIndex = 1;
        let totalAdjustments = 0;
        while (index < count - 2) {
            let grade = grades[index];
            // get the grade from previous point to current point to next point
            // and also check this grade to be above the threshold, so we don't pick up
            // and smooth sections that single point anomolies
            if (Math.abs(grade) > gradeThreshold) {
                startIndex = index;
                let isGradePositive = grade > 0;
                // Find the ending point for series of indices with bad elevations
                endIndex = funcs.findEndIndex(startIndex, isGradePositive, grades, distances, gradeThreshold);
                // If an ending index was found, modify the indices and grades
                // for all points between startIndex and EndIndex inclusive.
                if (endIndex > startIndex) {
                    let startElevation = elevations[startIndex - 1];
                    let endElevation = elevations[endIndex];
                    // Check to see that the end elevation is within the tolerance
                    // expected for the distance between the start and end points.
                    let run = _.sum(distances.slice(startIndex + 1, endIndex + 1));
                    let rise = endElevation - startElevation;
                    //let sign = endElevation > startElevation ? 1.0 : -1.0;
                    //let slope = rise / run;
                    // maximum slope expected for any road multiplied by the distance
                    // between the start and end points where erroneous elevations were
                    // detected.
                    let maxAllowedElevationChange = (run * gradeThreshold) / 100.0;
                    if (Math.abs(rise) < maxAllowedElevationChange) {
                        let distance = 0;
                        let adjustments = 0;
                        for (let index = startIndex; index <= endIndex; index++) {
                            distance += distances[index];
                            let newElevation = startElevation + (distance / run) * rise;
                            let newGrade = ((newElevation - elevations[index - 1]) / distances[index]) * 100.0;
                            elevations[index] = newElevation;
                            grades[index] = newGrade;
                            adjustments += 1;
                        }
                        console.log(`adjustElevations: adjusted ${adjustments}`);
                        totalAdjustments += adjustments;
                    }
                }
                index = endIndex;
            }
            index += 1;
        }
        console.log(`adjustElevations: total adjusted ${totalAdjustments}`);
    },

    findEndIndex: function (index, isStartGradePositive, grades, distances, gradeThreshold) {
        //const MaxDistance = 10560; // Max distance allowed between start and end, 2 mi
        const MaxDistance = 5280; // Max distance allowed between start and end, 1 mi
        let isFoundEndIndex = false;
        let startIndex = index;
        let endIndex = index + 1;
        let grade = grades[endIndex];
        let isEndGradePositive = grade > 0;
        let distance = distances[endIndex];
        let count = grades.length;
        // to find an end point:
        // distance has to be less than 0.5 miles.
        // magnitude of grade needs to be greater than threshold.
        while (distance <= MaxDistance && endIndex < count - 2) {
            if (isStartGradePositive !== isEndGradePositive && Math.abs(grade) > gradeThreshold) {
                // Now search for other side of group of erroneous elevations.
                let belowThresholdCount = 0;
                const Threshold_Count = 6;
                while (belowThresholdCount < Threshold_Count && distance <= MaxDistance) {
                    endIndex += 1;
                    grade = grades[endIndex];
                    distance += distances[endIndex];
                    belowThresholdCount = Math.abs(grade) <= gradeThreshold ? belowThresholdCount + 1 : 0;
                }
                // If not distance > MaxDistance, and
                if (!(distance > MaxDistance)) {
                    isFoundEndIndex = true;
                }
                break;
            } else {
                endIndex += 1;
                distance += distances[endIndex];
                grade = grades[endIndex];
                isEndGradePositive = grade > 0;
            }
        }
        return isFoundEndIndex ? endIndex : startIndex;
    },

    filterSuspectPoints: function (distances, elevations, grades, elevationPath) {
        // First pass, simply remove any points that result in a absolute grade greater
        // than .08 -- for interstate and state highways 18 wheelers travel should not have a grade above 8
        // so grades above that will be considered bogus

        // Note that distances in the distance array start with the second element.  That is the second
        // element in the array is the distance of the first element in the path to the second element
        // This is important for the rise/run calculations for grade.  The very first element will have
        // a distance of zero.
        const gradeThreshold = 8.5; // 8.5% grade is max grade expected
        //const gradientChangeThreshold = 5.0; // 5% is maximum grade change expected
        let removeIndices = [];
        let removedDistance = 0;

        let index = 0;
        let nextIndex = 0;
        let rise = 0;
        let elevation = 0;
        let nextElevation = 0;
        // let distance = 0;
        let nextDistance = 0;
        // let totalDistance = 0;
        // let totalDistanceMiles = 0;
        let grade = 0;
        //let lastGradient = 0;
        //let gradientChangePercent = 0;

        while (index < distances.length - 2) {
            distances[index] += removedDistance;
            // totalDistance += distances[index];
            // totalDistanceMiles = (totalDistance / 5280).toFixed(3);
            nextIndex = index + 1;
            elevation = elevations[index];
            nextElevation = elevations[nextIndex];
            rise = nextElevation - elevation;
            // distance = distances[nextIndex];
            grade = grades[nextIndex];
            //lastGradient = grade;
            //gradientChangePercent = Math.abs(grade - lastGradient);

            removedDistance = 0;
            while (
                Math.abs(grade) > gradeThreshold //|| //gradientChangePercent > gradientChangeThreshold
            ) {
                // Add distance of removed point to prior element
                // This is necessary for the elevation graph to be correct
                removedDistance += distances[nextIndex];
                removeIndices.push(nextIndex);
                nextIndex++;
                nextElevation = elevations[nextIndex];
                nextDistance = distances[nextIndex] + removedDistance;
                rise = nextElevation - elevation;
                //lastGradient = grade;
                grade = (rise / nextDistance) * 100; // rise over run
                //gradientChangePercent = Math.abs(grade - lastGradient);
            }
            index = nextIndex;
            grades[nextIndex] = grade; // assign new caclulated grade between this
        }
        console.log(`filterSuspectPoints: removing ${removeIndices.length} items from path arrays`);
        _.pullAt(distances, removeIndices);
        _.pullAt(elevations, removeIndices);
        _.pullAt(grades, removeIndices);
        _.pullAt(elevationPath, removeIndices);
    }
};

export default funcs;